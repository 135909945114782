import React from 'react'

const ThesisCard = ({thesis}) => {

    const truncate = (input, length) => input.length > length ? `${input.substring(0, length)}...` : input;

  return (
    <div className='group flex flex-row justify-between px-5 mr-6 mb-4 border rounded-2xl p-1.5 min-h-20 cursor-pointer transform hover:bg-[#FBFBFB]'>
        <div className='flex flex-row items-center w-full'>  
            <div className='flex flex-row items-center w-[22%] pr-6'>
                <div className='flex items-center justify-center mr-3 min-h-10 min-w-10 rounded-full bg-[#F1F3FF]'>
                    <span>
                        <i className={`${thesis.icon} text-[#5A6ACF]`}></i>
                    </span>
                </div>
                <div className='flex flex-col'>
                    <div className='text-sm font-medium mb-1'>
                        {thesis.name}
                    </div>
                    <div className='text-xs text-gray-400'>
                        {thesis.tag}
                    </div>
                </div>
                
            </div>

            <div className='flex w-[10%] px-1'>
                <div className='flex flex-col'>
                    <div className='text-sm font-medium mb-1'>
                        {thesis.totalParams}
                    </div>
                    <div className='text-xs text-gray-400'>
                        Params
                    </div>
                </div>
            </div>

            <div className='flex w-[10%] px-1'>
                <div className='flex flex-col'>
                    <div className='text-sm font-medium mb-1'>
                        {
                            thesis.type === 'weighted' ? (
                                <span>
                                    Weighted
                                </span>
                            ) : (
                                <span>
                                    Even
                                </span>
                            )
                        }
                    </div>
                    <div className='text-xs text-gray-400'>
                        Type
                    </div>
                </div>
            </div>

            <div className='flex w-[10%] px-1'>
                <div className='flex flex-col'>
                    <div className='text-sm font-medium mb-1'>
                        {
                            thesis.active ? (
                                <span>
                                    Yes
                                </span>
                            ) : (
                                <span>
                                    No
                                </span>
                            )
                        }
                    </div>
                    <div className='text-xs text-gray-400'>
                        Active
                    </div>
                </div>
            </div>

            <div className='flex w-[10%] px-1'>
                <div className='flex flex-col'>
                    <div className='text-sm font-medium mb-1'>
                        {thesis.rating}
                    </div>
                    <div className='text-xs text-gray-400'>
                        Rating
                    </div>
                </div>
            </div>

            <div className='flex w-[28%] px-1'>
                <div className='flex flex-col'>
                    <div className='text-sm font-medium mb-1'>
                        {truncate(thesis.description, 50)}
                    </div>
                    <div className='text-xs text-gray-400'>
                        Description
                    </div>
                </div>
            </div>
        </div> 

        <div className='flex items-center'>
            <span>
                <i className="fas fa-chevron-right fa-xs text-gray-400"></i>
            </span>
        </div>

    </div>
  )
}

export default ThesisCard