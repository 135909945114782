import "./App.css";
import { BrowserRouter } from "react-router-dom";
import AnimatedRoutes from "./customComponents/AnimatedRoutes";
import { SupabaseAuthContextProvider } from "./context/SupabaseAuthContext";

function App() {
  return (
    <SupabaseAuthContextProvider>
      <BrowserRouter>
        <AnimatedRoutes />
      </BrowserRouter>
    </SupabaseAuthContextProvider>
  );
}

export default App;
