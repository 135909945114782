import React from 'react'

const StockMainCellCp = ({params}) => {
  return (
    <div style={{display:'flex', flexDirection:'column'}}>
      <div style={{fontSize:'15px', marginBottom:'3px'}}>
          {params.value}
      </div>
      <div style={{fontSize:'11px', fontWeight:'300'}}>
          {params.row.tikr}
      </div>
    </div>
  )
}

export default StockMainCellCp