import React, {useEffect, useRef} from 'react'
import * as echarts from 'echarts';

const PriceChart = ({stockPriceData}) => {

    const chartRef = useRef(null);

    useEffect(() => {

      const chart = echarts.init(chartRef.current);
  
      const options = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            },
            formatter: (params) => {
              return `${params[0].name} <br/> Price: ${params[0].value} $`
            }
          },
          grid: {
            left: '0%',
            right: '3%',
            bottom: '2%',
            top: '3%',
            containLabel: true
          },
          xAxis: [
            {
              type: 'category',
              boundaryGap: false,
              data: stockPriceData.dates,
              axisLine: {
                  show: false,
                  lineStyle: {
                    color: 'transparent',
                    width: '0',
                  },
              },
              axisLabel: 'none',
            }
          ],
          yAxis: [
            {
              type: 'value',
              axisLabel: {
                  formatter: val => `${val} $`
              }
            }
          ],
          series: [
            {
              name: 'Price',
              type: 'line',
              stack: 'Total',
              smooth: true,
              lineStyle: {
                width: 3
              },
              showSymbol: false,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#5A6ACF'
                  },
                  {
                    offset: 1,
                    color: '#FFFFFF'
                  }
                ])
              },
              emphasis: {
                focus: 'series'
              },
              data: stockPriceData.values
            }
          ]
      };
  
      chart.setOption(options);

      const resizeChart = () => {
        chart.resize();
      };

      window.addEventListener('resize', resizeChart);
  
      return () => {
        chart.dispose();
        window.removeEventListener('resize', resizeChart);
      };

    }, [stockPriceData]);

  return (
    
    <div ref={chartRef} className=' h-96 w-full'></div>
    
    
  )
}

export default PriceChart