import React from 'react'

const RatioCell = ({params, year}) => {
    return (
        <div className='flex flex-row'>
            {
                params.row.values[year] >= 0 ? (
                    <div>
                        <div>{(Number(params.row.values[year])*100).toFixed(1) + "%"}</div>
                        <div>{params.row.metricSufix}</div> 
                    </div>
                ): params.row.values[year] < 0 ? (
                    <div>
                        <div className='text-red-700'>
                            {"(" + (Number(params.row.values[year])*100).toFixed(1) + "%)"}
                        </div>
                        <div>{params.row.metricSufix}</div> 
                    </div>
                )
                :
                null
            }
        </div>
    )
}

export default RatioCell