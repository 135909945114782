import { useContext, createContext, useEffect, useState } from "react";
import supabase from "../config/supabase";

const SupabaseAuthContext = createContext();

export const SupabaseAuthContextProvider = ({ children }) => {
  //const [user, setUser] = useState({});

  const [session, setSession] = useState(null);

  const supabaseMailSignIn = async (mail, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: mail,
      password: password,
    });
    if (data) {
      return data;
    } else if (error) {
      console.log("User login error");
      return error;
    }
  };

  const supabaseMailSignUp = async (mail, password) => {
    const { data, error } = await supabase.auth.signUp({
      email: mail,
      password: password,
    });
    if (data) {
      return data;
    } else if (error) {
      console.log(error);
      return error;
    }
  };

  const supabaseSignOut = async () => {
    const { error } = await supabase.auth.signOut();
  };

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <SupabaseAuthContext.Provider
      value={{
        supabaseSignOut,
        supabaseMailSignUp,
        supabaseMailSignIn,
        session,
      }}
    >
      {children}
    </SupabaseAuthContext.Provider>
  );
};

export const SupabaseUserAuth = () => {
  return useContext(SupabaseAuthContext);
};
