import React from 'react'

const LargeDigitCell = ({params, year}) => {

    function formatNumber(num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    }

  return (
    <div className='flex flex-row'>
        {
            params.row.redNumber == false ? (
                params.row.values[year] >= 0 ? (
                    <div className={params.row.rowType == "total" ? 'font-extrabold' : null}>
                        <div className='flex flex-col'>
                            <div>{formatNumber(Number((params.row.values[year])/1000000).toFixed())}</div>
                            {
                                params.row.growth == undefined ? null : params.row.growth[year] >= 0 ? (
                                    <div className='text-green-700 font-normal text-[11px] mt-0.5'>
                                        {"+" + (params.row.growth[year]*100).toFixed(2) + "%"}
                                    </div>
                                ): params.row.growth[year] < 0 ? (
                                    <div className='text-red-700 font-normal text-[11px] mt-0.5'>
                                        {(params.row.growth[year]*100).toFixed(2) + "%"}
                                    </div>
                                ): null
                            }
                        </div>
                       
                        <div>{params.row.metricSufix}</div> 
                    </div>
                ): params.row.values[year] < 0 ? (
                    <div>
                        <div className={params.row.rowType == "total" ? 'font-extrabold text-red-700' : 'text-red-700'}>
                            <div className='flex flex-col'>
                                {"(" + formatNumber(Math.abs(Number((params.row.values[year])/1000000).toFixed())) + ")"}
                                {
                                    params.row.growth == undefined ? null : params.row.growth[year] >= 0 ? (
                                        <div className='text-green-700 font-normal text-[11px] mt-0.5'>
                                            {"+" + (params.row.growth[year]*100).toFixed(2) + "%"}
                                        </div>
                                    ): params.row.growth[year] < 0 ? (
                                        <div className='text-red-700 font-normal text-[11px] mt-0.5'>
                                            {(params.row.growth[year]*100).toFixed(2) + "%"}
                                        </div>
                                    ): null
                                }
                            </div>
                        </div>
                        <div>{params.row.metricSufix}</div> 
                    </div>
                )
                :
                null
            )
            : params.row.redNumber == true ? (
                params.row.values[year] >= 0 ? (
                    <div>
                        <div className={params.row.rowType == "total" ? 'font-extrabold text-red-700' : 'text-red-700'}>
                            <div className='flex flex-col'>
                                {"(" + formatNumber(Math.abs(Number((params.row.values[year])/1000000).toFixed())) + ")"}
                                {
                                    params.row.growth == undefined ? null : params.row.growth[year] >= 0 ? (
                                        <div className='text-green-700 font-normal text-[11px] mt-0.5'>
                                            {"+" + (params.row.growth[year]*100).toFixed(2) + "%"}
                                        </div>
                                    ): params.row.growth[year] < 0 ? (
                                        <div className='text-red-700 font-normal text-[11px] mt-0.5'>
                                            {(params.row.growth[year]*100).toFixed(2) + "%"}
                                        </div>
                                    ): null
                                }
                            </div>
                        </div>
                        <div>{params.row.metricSufix}</div> 
                    </div>
                ): params.row.values[year] < 0 ? (
                    <div>
                        <div className={params.row.rowType == "total" ? 'font-extrabold text-red-700' : 'text-red-700'}>
                            <div className='flex flex-col'>
                                {"(" + formatNumber(Math.abs(Number((params.row.values[year])/1000000).toFixed())) + ")"}
                                {
                                    params.row.growth == undefined ? null : params.row.growth[year] >= 0 ? (
                                        <div className='text-green-700 font-normal text-[11px] mt-0.5'>
                                            {"+" + (params.row.growth[year]*100).toFixed(2) + "%"}
                                        </div>
                                    ): params.row.growth[year] < 0 ? (
                                        <div className='text-red-700 font-normal text-[11px] mt-0.5'>
                                            {(params.row.growth[year]*100).toFixed(2) + "%"}
                                        </div>
                                    ): null
                                }
                            </div>
                        </div>
                        <div>{params.row.metricSufix}</div> 
                    </div>
                )
                :
                null
            )
            :
            null
            
        }
    </div>
  )
}

export default LargeDigitCell