import React from 'react'

const SmallDigitCell = ({params, year}) => {
    return (
        <div className='flex flex-row'>
            {
                params.row.values[year] >= 0 ? (
                    <div>
                        <div className='flex flex-col'>
                            <div>{Number(params.row.values[year])}</div>
                            {
                                params.row.growth == undefined ? null : params.row.growth[year] >= 0 ? (
                                    <div className='text-green-700 font-normal text-[11px] mt-0.5'>
                                        {"+" + (params.row.growth[year]*100).toFixed(2) + "%"}
                                    </div>
                                ): params.row.growth[year] < 0 ? (
                                    <div className='text-red-700 font-normal text-[11px] mt-0.5'>
                                        {(params.row.growth[year]*100).toFixed(2) + "%"}
                                    </div>
                                ): null
                            }
                        </div>
                        <div>{params.row.metricSufix}</div> 
                    </div>
                ): params.row.values[year] < 0 ? (
                    <div>
                        <div className='flex flex-col'>
                            <div className='text-red-700'>
                                {"(" + Number(params.row.values[year]) + ")"}
                            </div>
                            {
                                params.row.growth == undefined ? null : params.row.growth[year] >= 0 ? (
                                    <div className='text-green-700 font-normal text-[11px] mt-0.5'>
                                        {"+" + (params.row.growth[year]*100).toFixed(2) + "%"}
                                    </div>
                                ): params.row.growth[year] < 0 ? (
                                    <div className='text-red-700 font-normal text-[11px] mt-0.5'>
                                        {(params.row.growth[year]*100).toFixed(2) + "%"}
                                    </div>
                                ): null
                            }
                        </div>
                        <div>{params.row.metricSufix}</div> 
                    </div>
                )
                :
                null
            }
        </div>
    )
}

export default SmallDigitCell