import React, {useState, useEffect} from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Login from '../pages/Login'
import Register from '../pages/Register';
import Home from '../pages/Home';
import Dashboard from '../pages/Dashboard';
import Portfolio from '../pages/Portfolio';
import Analyzer from '../pages/Analyzer';
import Watchlist from '../pages/Watchlist';
import Thesis from '../pages/Thesis';
import Settings from '../pages/Settings';
import Account from '../pages/Account';
import Help from '../pages/Help';
import CreatePortfolio from '../pages/CreatePortfolio';
import CreateWatchlist from '../pages/CreateWatchlist';
import EditPortfolio from '../pages/EditPortfolio';
import CreateThesis from '../pages/CreateThesis';
import EditWatchlist from '../pages/EditWatchlist';

const AnimatedRoutes = () => {

    const location = useLocation();
  
    return (
     
      <Routes location={location} key={location.pathname}>
  
        <Route path='/' element={<Login/>}></Route>
        <Route path='/Register' element={<Register/>}></Route>
        
        <Route path='/Home' element={<Home/>}>
          <Route path='/Home/Dashboard' element={<Dashboard/>}></Route>
          <Route path='/Home/Portfolio' element={<Portfolio/>}></Route>
          <Route path='/Home/CreatePortfolio' element={<CreatePortfolio/>}></Route>
          <Route path='/Home/EditPortfolio/:portfolioId' element={<EditPortfolio/>}></Route>
          <Route path='/Home/EditWatchlist/:watchlistId' element={<EditWatchlist/>}></Route>
          <Route path='/Home/CreateWatchlist' element={<CreateWatchlist/>}></Route>
          <Route path='/Home/Analyzer/:stockId' element={<Analyzer/>}></Route>
          <Route path='/Home/Watchlist' element={<Watchlist/>}></Route>
          <Route path='/Home/Thesis' element={<Thesis/>}></Route>
          <Route path='/Home/CreateThesis' element={<CreateThesis/>}></Route>
          <Route path='/Home/Settings' element={<Settings/>}></Route>
          <Route path='/Home/Account' element={<Account/>}></Route>
          <Route path='/Home/Help' element={<Help/>}></Route>
        </Route>
          
      </Routes>
      
    )
  }
  
  export default AnimatedRoutes