import React, {useState, useEffect} from 'react'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "../components/ui/select.jsx"
import { Input } from "../components/ui/input.jsx"


const ThesisParamRow = ({param, parametersSelected, setParametersSelected, index}) => {

    const [paramSelected, setPramSelected] = useState('');
    const [paramInputSelected, setParamInputSelected] = useState('');

    const paramOptions = [
       
        { name: 'PER1', label: 'PER', type: 'absolutes 1', group: 'absolutes', valueSufix: 'X'},
        { name: 'PBR1', label: 'PBR', type: 'absolutes', group: 'absolutes', valueSufix: 'X'},
        { name: 'ROE1', label: 'ROE', type: 'absolutes', group: 'absolutes', valueSufix: 'X'},
        { name: 'ROA1', label: 'ROA', type: 'absolutes', group: 'absolutes', valueSufix: 'X'},
        { name: 'Debt1', label: 'Debt', type: 'absolutes', group: 'absolutes', valueSufix: 'X' },
        { name: 'Liquidity1', label: 'Liquidity balance', type: 'absolutes' , group: 'absolutes', valueSufix: 'MM USD'},
        { name: 'Volume1', label: '24 Hours Trading Volume', type: 'absolutes' , group: 'absolutes', valueSufix: 'MM USD'},
    
        { name: 'PER2', label: 'PER', type: 'absolutes 2', group: 'ratios', valueSufix: 'X' },
        { name: 'PBR2', label: 'PBR', type: 'absolutes', group: 'ratios', valueSufix: 'X' },
        { name: 'ROE2', label: 'ROE', type: 'absolutes', group: 'ratios', valueSufix: 'X'  },
        { name: 'ROA2', label: 'ROA', type: 'absolutes', group: 'ratios', valueSufix: 'X'  },
        { name: 'Debt2', label: 'Debt', type: 'absolutes', group: 'ratios', valueSufix: 'X'  },
        { name: 'Liquidity2', label: 'Liquidity balance', type: 'absolutes', group: 'ratios', valueSufix: 'MM USD'  },
        { name: 'Volume2', label: '24 Hours Trading Volume', type: 'absolutes', group: 'ratios', valueSufix: 'MM USD'  },
    
        { name: 'PER3', label: 'PER', type: 'absolutes 3', group: 'indicators', valueSufix: 'X'  },
        { name: 'PBR3', label: 'PBR', type: 'absolutes', group: 'indicators', valueSufix: 'X'  },
        { name: 'ROE3', label: 'ROE', type: 'absolutes', group: 'indicators', valueSufix: 'X'  },
        { name: 'ROA3', label: 'ROA', type: 'absolutes', group: 'indicators', valueSufix: 'X'  },
        { name: 'Debt3', label: 'Debt', type: 'absolutes', group: 'indicators', valueSufix: 'X'  },
        { name: 'Liquidity3', label: 'Liquidity balance', type: 'absolutes', group: 'indicators', valueSufix: 'MM USD'  },
        { name: 'Volume3', label: '24 Hours Trading Volume', type: 'absolutes', group: 'indicators', valueSufix: 'MM USD'  },
            
        
    ]

    const removeParam = () => {
        const newParams = parametersSelected.filter((p) => p.id !== param.id);
        console.log(param.id)
        setParametersSelected(newParams);
    }

    useEffect(() => {
        
    }, [])

  return (
    <div className='flex flex-row items-center justify-between w-full border-b-[1px] py-4'>

        <div className='flex flex-row gap-6 h-full'>

            <div className='flex flex-col items-center justify-center'>
                <div className='flex items-center justify-center min-h-6 min-w-6 max-h-6 max-w-6 text-sm rounded-md text-[#5A6ACF] bg-slate-100'>
                    {index + 1}
                </div>
            </div>

            <div className='flex flex-col'>
                <div className='text font-medium'>
                    <Select
                        value={param.name}
                        className="z-30"
                        onValueChange={(value) => {
                            setPramSelected(value)
                            setParametersSelected(parametersSelected.map((p) => {
                                if(p.id === (param.id)) {
                                    return {
                                        ...p,
                                        name: value,
                                        type: paramOptions.find((v) => v.name === value).group
                                    }
                                }
                                return p;
                            }))
                        }}
                    >
                        <SelectTrigger className="max-w-[250px]">
                            <SelectValue placeholder="Select a param" />
                        </SelectTrigger>
                        <SelectContent>
  
                            <SelectGroup>
                                <SelectLabel>Absolutes</SelectLabel>
                                {
                                    paramOptions.map((item, index) => (
                                        item.group === 'absolutes' ?
                                            <SelectItem key={index} value={item.name}>{item.label}</SelectItem>
                                        :null
                                        
                                    ))
                                }
                            </SelectGroup>

                            <SelectGroup>
                                <SelectLabel>Ratios</SelectLabel>
                                {
                                    paramOptions.map((item, index) => (
                                        item.group === 'ratios' ?
                                            <SelectItem key={index} value={item.name}>{item.label}</SelectItem>
                                        :null
                                        
                                    ))
                                }
                            </SelectGroup>

                            <SelectGroup>
                                <SelectLabel>Indicators</SelectLabel>
                                {
                                    paramOptions.map((item, index) => (
                                        item.group === 'indicators' ?
                                            <SelectItem key={index} value={item.name}>{item.label}</SelectItem>
                                        :null
                                        
                                    ))
                                }
                            </SelectGroup>
                    
                        </SelectContent>
                        
                    </Select>
                </div>
                <div className='text-xs text-gray-400 mt-2'>
                    Param
                </div>
            </div>

            {
                param.type === 'absolutes' ? (
                <>
                    <div>    
                        <div className='flex flex-col'>
                            <div className='text font-medium'>
                                <Select 
                                    className="border" 
                                    onValueChange={(value) => {
                                        setParametersSelected(parametersSelected.map((p) => {
                                            if(p.id === (param.id)) {
                                                return {
                                                    ...p,
                                                    paramSelectionDetails : {
                                                        ...p.paramSelectionDetails,
                                                        metricOperator: value,
                                                    }
                                                }
                                            }
                                            return p;
                                        }
                                    ))}}
                                >
                                    <SelectTrigger className="">
                                        <SelectValue placeholder="Operator" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value=">">Greater than</SelectItem>
                                        <SelectItem value=">=">Greater or equal to</SelectItem>
                                        <SelectItem value="<">Less than</SelectItem>
                                        <SelectItem value="<=">Less than or equal to</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-row'>
                        <Input
                            className="w-40"
                            type="number" 
                            placeholder="Metric" 
                            onChangeCapture={(e) => {
                                setParametersSelected(parametersSelected.map((p) => {
                                    if(p.id === (param.id)) {
                                        return {
                                            ...p,
                                            paramSelectionDetails : {
                                                ...p.paramSelectionDetails,
                                                metricValue: e.currentTarget.value,
                                            }
                                        }
                                    }
                                    return p;
                                }))
                            }}
                        />
                        <div className='text-sm font-medium ml-2 pt-3.5 w-fit'>
                            {
                                paramOptions.find((v) => v.name === param.name).valueSufix
                            }
                        </div>
                    </div>
                </>
                ):null
            }

            
        </div>

        <div className='flex flex-row items-center'>
            <div className='flex flex-col mr-8'>
                <div className='flex flex-row items-center'>
                    <div className='flex gap-0 flex-col mr-3'>
                        <div 
                            onClick={
                                () => {
                                    setParametersSelected(parametersSelected.map((p) => {
                                        if(p.id === (param.id) && p.weight < 9) {
                                            return {
                                                ...p,
                                                weight: p.weight + 1
                                            }
                                        }
                                        return p;
                                    }))
                                }
                            }
                        >
                            <span className='text-xs py-0.5 px-1 text-gray-600 hover:bg-gray-100 hover:rounded-full cursor-pointer'>
                                <i class="fa-solid fa-chevron-up fa-sm"></i>
                            </span>
                        </div>
                        <div 
                            onClick={
                                () => {
                                    setParametersSelected(parametersSelected.map((p) => {
                                        if(p.id === (param.id) && p.weight > 1) {
                                            return {
                                                ...p,
                                                weight: p.weight - 1
                                            }
                                        }
                                        return p;
                                    }))
                                }
                            }
                        >
                            <span className='text-xs py-0.5 px-1 text-gray-600 hover:bg-gray-100 hover:rounded-full cursor-pointer'>
                                <i class="fa-solid fa-chevron-down fa-sm"></i>
                            </span>
                        </div>
                    </div>
                    <div>
                        {
                            param.weight === null ? (
                                0
                            ):param.weight
                        }
                    </div>
                </div>
                <div className='text-xs text-gray-400 mt-2'>
                    Weight
                </div>
            </div>

            <div className='flex flex-row items-center bg-gray-100 rounded-full max-h-9 px-5 py-2 hover:bg-gray-200 cursor-pointer' onClick={()=>removeParam()}>
                <span className='text-[#CC7E7E] text-xs'>
                    <i class="fa-solid fa-trash-can fa-sm mr-2"></i>
                </span>
                <span className='text-xs text-[#CC7E7E]'>
                    Remove
                </span>
            </div>
        </div>

        
        
    </div>
  )
}

export default ThesisParamRow