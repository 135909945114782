import React, {useState, useEffect} from 'react'
import Rating from '@mui/material/Rating';
import { Switch } from "../components/ui/switch";
import { TextField } from '@mui/material'
import Popover from '@mui/material/Popover';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';
import { Alert } from '@mui/material';
import StockVolumeCell from '../customComponents/StockVolumeCell';
import StockAveragePriceCell from '../customComponents/StockAveragePriceCell';
import StockMainCellCp from '../customComponents/StockMainCellCp'
import DeleteStockCell from '../customComponents/DeleteStockCell'
import { SupabaseUserAuth } from '../context/SupabaseAuthContext'
import supabase from '../config/supabase';
import '../styles/CreatePortfolio.css'

const CreatePortfolio = () => {

    const { session } = SupabaseUserAuth()

    const [active, setActive] = useState(true);

    //Popover
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    //Popover end

    //Alert
    const [openAlert, setOpenAlert] = useState(false);
    //Alert

    //Dinamic search States
    const [search, setSearch] = useState('')
    const [searchResults, setSearchResults] = useState([])
    const [selectedStock, setSelectedStock] = useState(null)
    const [searchResultsLoading, setSearchResultsLoading] = useState(false)
    const [searchResultsVisible, setSearchResultsVisible] = useState(true)
    const [volumeInput, setVolumeInput] = useState(null)
    const [priceInput, setPriceInput] = useState(null)

    const handleSearchChange = (value) => {
        setSearch(value) 
    }
    //Dinamic search States end

    const [rating, setRating] = useState(0);
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [includeVolume, setIncludeVolume] = useState(false)
    const [includePrice, setIncludePrice] = useState(false)
    const [portfolioBeingCreated, setPortfolioBeingCreated] = useState(false)
    const [portfolioCreated, setPortfolioCreated] = useState(false)
    const [inputsEnabler , setInputsEnabler] = useState(false)

    //Rows
    const [stocks, setStocks] = useState([])
    //Rows

    //Columns
    const tableColumnOptions = {
        kpis:[
            { 
              field: 'stock', 
              headerName: 'Stock', 
              width: 140, 
              align: 'left', 
              headerAlign: 'center',
              renderCell: (params) => {
                return(
                  <StockMainCellCp params={params}/> 
                )
              } 
            },
            { 
              field: 'id',
              headerName: '', 
              width: 140, 
              align: 'center', 
              headerAlign: 'center',
              renderCell: (params) => {
                return(
                    inputsEnabler === true ? null :
                  <DeleteStockCell params={params} setStocks={setStocks} stocks={stocks}/> 
                )
              } 
            }
        ],
        kpisVolumePrice:[
            { 
                field: 'stock', 
                headerName: 'Stock', 
                width: 140, 
                align: 'left', 
                headerAlign: 'center',
                renderCell: (params) => {
                return(
                    <StockMainCellCp params={params}/> 
                )
                } 
            },
            { 
                field: 'volume', 
                headerName: 'N of Shares', 
                width: 140, 
                align: 'center', 
                headerAlign: 'center',
                renderCell: (params) => {
                return (
                    <StockVolumeCell params={params}/>
                );
                } 
            },
            { 
                field: 'averagePrice', 
                headerName: 'Average Price', 
                width: 140, 
                align: 'center', 
                headerAlign: 'center',
                renderCell: (params) => {
                return (
                    <StockAveragePriceCell params={params}/>
                );
                } 
            },
            { 
                field: 'id',
                headerName: '', 
                width: 140, 
                align: 'center', 
                headerAlign: 'center',
                renderCell: (params) => {
                    return(
                        inputsEnabler === true ? null :
                      <DeleteStockCell params={params} setStocks={setStocks} stocks={stocks}/> 
                    )
                } 
            }
        ],
        kpisVolume:[
            { 
              field: 'stock', 
              headerName: 'Stock', 
              width: 140, 
              align: 'left', 
              headerAlign: 'center',
              renderCell: (params) => {
                return(
                  <StockMainCellCp params={params}/> 
                )
              } 
            },
            { 
              field: 'volume', 
              headerName: 'N of Shares', 
              width: 140, 
              align: 'center', 
              headerAlign: 'center',
              renderCell: (params) => {
                return (
                  <StockVolumeCell params={params}/>
                );
              } 
            },
            { 
              field: 'id',
              headerName: '', 
              width: 140, 
              align: 'center', 
              headerAlign: 'center',
              renderCell: (params) => {
                return(
                    inputsEnabler === true ? null :
                  <DeleteStockCell params={params} setStocks={setStocks} stocks={stocks}/> 
                )
              } 
            }
        ],
        kpisPrice:[
            { 
              field: 'stock', 
              headerName: 'Stock', 
              width: 140, 
              align: 'center', 
              headerAlign: 'center',
              renderCell: (params) => {
                return(
                  <StockMainCellCp params={params}/> 
                )
              } 
            },
            { 
              field: 'averagePrice', 
              headerName: 'Average Price', 
              width: 140, 
              align: 'center', 
              headerAlign: 'center',
              renderCell: (params) => {
                return (
                  <StockAveragePriceCell params={params}/>
                );
              } 
            },
            { 
              field: 'id',
              headerName: '', 
              width: 140, 
              align: 'center', 
              headerAlign: 'center',
              renderCell: (params) => {
                return(
                    inputsEnabler === true ? null :
                  <DeleteStockCell params={params} setStocks={setStocks} stocks={stocks}/> 
                )
              } 
            }
        ]
    }
    //Columns

    const crateNewPortfolio = async () => {

        if(name === '' || description === '' || stocks.length === 0 ){
            setOpenAlert(true)
            return

        }else{

            setPortfolioBeingCreated(true)
            
            const newPortfolio = {
                userId: session.user.id,
                stocks: stocks,
                portfolioInfo: {
                    rating: rating,
                    active: active,
                    name: name,
                    description: description,
                }
            }

            const {data, error} = await supabase.functions.invoke('create-portfolio', {
                body: newPortfolio
            })

            if(error){
                console.log(error)
                setOpenAlert(true)
            }else{
                console.log(data)
                setPortfolioBeingCreated(false)
                setPortfolioCreated(true)
                setInputsEnabler(true)
            }

        }
    }

    const searchBlur = () => {
        setTimeout(() => {
          setSearchResultsVisible(false);
        }, 200);
    }
    
    const searchFocus = () => {
        if (searchResults.length > 0) {
            setSearchResultsVisible(true)
        } 
    }
    
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setSearchResultsLoading(true)
            getSearchResults()
        }
    }
    
    const getSearchResults = async () => {
        const { data, error } = await supabase.functions.invoke('search-stocks-by-name', {
            body: { keyword: search }
        })
        console.log('entra a buscar')
        setSearchResults(data.filter(stock => !stocks.find(s => s.tikr === stock.symbol)))
        setSearchResultsVisible(true)
        setSearchResultsLoading(false)
    }

    const selectStock = (stock) => {
        setSearch(stock.name)
        setSelectedStock(stock)
        setSearchResultsVisible(false)
    }

    const addStock = () => {
        if(selectedStock !== null){
            const newStock = {
                id: stocks.length + 1,
                stock: selectedStock.name,
                tikr: selectedStock.symbol,
                volume: volumeInput === '' ? null : volumeInput,
                averagePrice: priceInput === '' ? null : priceInput
            }
            setStocks([...stocks, newStock])
            setSearch('')
            setSearchResults([])
            setSelectedStock(null)
            setVolumeInput(null)
            setPriceInput(null)
            handleClose()
        }
    
    }

    useEffect(() => {

    }
    , [stocks])
    
  return (
    <div className='cp-container'>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{
              borderRadius: '25px'
            }}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
        >
          <div className='topbar-popover-container'>
            <div className='topbar-popover-container-padding-helper'>
                <div style={{padding: '20px', display:'flex', alignItems:'center', flexDirection:'column'}}>
                    <div className='cp-form-field-container'>
                        <TextField   
                            label='Stock search' 
                            variant='outlined'
                            size='small'
                            onFocus={searchFocus}
                            onBlur={searchBlur}
                            onKeyDown={handleKeyDown}
                            InputLabelProps={{ style: { color: '#AEB1B4', fontFamily:'poppins', fontSize: 13  }}}
                            inputProps={{style: {fontSize: 13, fontFamily:'poppins'}}}
                            onChange={(event) => {
                                handleSearchChange(event.target.value)
                            }}
                            value={search}
                            sx={{
                            width: '250px', 
                            height: '40px',
                            backgroundColor: 'none', 
                            borderRadius: '10px',
                            "& fieldset": { border: 'none' }
                            }}
                        />
                        <span className='topbar-searchbar-icon' style={{color:'#CECECE', paddingRight:'30px'}}>
                            <i class="fa-solid fa-magnifying-glass fa-xs"></i>
                        </span>
                    </div>
                    <div className='w-[290px] bg-white rounded-md flex flex-col max-h-32 z-100 overflow-scroll'>
                        {
                            searchResultsLoading ? <div className='w-full flex flex-row items-center justify-center gap-3 py-2 text-sm'>Searching...</div> : 
                            searchResultsVisible && searchResults.length > 0 ? 
                            searchResults.map((stock, index) => {
                            return (
                                <div key={index} className='flex flex-row px-2 py-3 hover:bg-gray-100 transition-colors duration-200 cursor-pointer' onClick={() => selectStock(stock)}>
                                <div className='text-blue-600 font-semibold text-xs mr-3 min-w-16'>
                                    {stock.symbol}
                                </div>
                                <div className='text-gray-800 text-sm'>
                                    {stock.name}
                                </div>
                                </div>
                            )
                            }): null
                        }
                    </div>
                    {
                        includeVolume === true ?(
                            <div className='cp-form-field-container'>
                                <TextField   
                                    label='N of shares' 
                                    variant='outlined'
                                    size='small'
                                    InputLabelProps={{ style: { color: '#AEB1B4', fontFamily:'poppins', fontSize: 13  }}}
                                    inputProps={{style: {fontSize: 13, fontFamily:'poppins'}}}
                                    onChange={(event) => {
                                        setVolumeInput(event.target.value.replace(/,/g, '.'))
                                    }}
                                    value={volumeInput}
                                    sx={{
                                    width: '250px', 
                                    height: '40px',
                                    backgroundColor: 'none', 
                                    borderRadius: '10px',
                                    "& fieldset": { border: 'none' }
                                    }}
                                />
                                <span className='topbar-searchbar-icon' style={{color:'#CECECE', paddingRight:'30px'}}>
                                    <i class="fa-solid fa-circle-half-stroke fa-sm"></i>
                                </span>
                            </div>
                        )
                        :
                        null
                    }
                    {
                        includePrice === true ?(
                        <div className='cp-form-field-container'>
                            <TextField   
                                label='Average price' 
                                variant='outlined'
                                size='small'
                                InputLabelProps={{ style: { color: '#AEB1B4', fontFamily:'poppins', fontSize: 13  }}}
                                inputProps={{style: {fontSize: 13, fontFamily:'poppins'}}}
                                onChange={(event) => {
                                    setPriceInput(event.target.value.replace(/,/g, '.'))
                                }}
                                value={priceInput}
                                sx={{
                                width: '250px', 
                                height: '40px',
                                backgroundColor: 'none', 
                                borderRadius: '10px',
                                "& fieldset": { border: 'none' }
                                }}
                            />
                            <span className='topbar-searchbar-icon' style={{color:'#CECECE', paddingRight:'30px'}}>
                                <i class="fa-solid fa-dollar-sign fa-sm"></i>
                            </span>
                        </div>
                        )
                        :
                        null
                    }
                    
                    <div className='flex flex-row bg-[#5a6acf] text-white py-3 px-4 mt-1 rounded-xl cursor-pointer items-center text-sm' onClick={()=> addStock()}>
                        <span style={{fontSize:'13px', marginRight:'10px'}}>
                            <i className="fas fa-plus"></i>
                        </span>
                        <div>
                            Add Stock
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </Popover>
        <div className='cp-padding-helper'>

            <div className='cp-top-banner'>
                <div className='cp-top-banner-left'>
                    <div className='portfolio-general-title'>
                        New Portfolio
                    </div>
                    <div className='cp-rating-container'>
                        <Rating
                            name="simple-controlled"
                            value={rating}
                            disabled={inputsEnabler}
                            onChange={(event, newValue) => {
                            setRating(newValue);
                            }}
                        />
                    </div>
                    <div className='portfolio-vertical-spacer'></div>
                    <div className='cp-active-container'>
                        <div className='portfolio-opportunities-toggle-title'>
                            Active
                        </div>
                        <Switch
                            checked={active}
                            disabled={inputsEnabler}
                            onCheckedChange={(checked) => {
                                setActive(checked);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                </div>
                <div className='cp-top-banner-right'>
                    <div className='portfolio-add-stock-button'>
                        {
                            portfolioBeingCreated === true && portfolioCreated === false?
                            (
                                <CircularProgress size={20} style={{color:'#5A67BA'}}/>
                            )
                            : portfolioBeingCreated === false && portfolioCreated === false ?
                            (
                                <div className='flex flex-row' onClick={() => crateNewPortfolio()}>
                                    <span style={{fontSize:'13px', marginRight:'10px'}}>
                                        <i className="fas fa-plus"></i>
                                    </span>
                                    <div>
                                        Create
                                    </div>
                                </div>
                            ): portfolioBeingCreated === false && portfolioCreated === true ?
                            (
                                <div className='flex flex-row cursor-default'>
                                    <span style={{fontSize:'13px', marginRight:'10px'}}>
                                        <i className="fas fa-check"></i>
                                    </span>
                                    <div>
                                        Created
                                    </div>
                                </div>
                            ):null
                            
                        }
                    </div>
                </div>
            </div>

            <div className='portfolio-table-spacer'></div>

            <div className='cp-bottom-container'>
                <div className='cp-form-container'>
                    <Collapse in={openAlert}>
                        <Alert
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setOpenAlert(false);
                            }}
                            >
                                <span>
                                    <i class="fa-solid fa-xmark"></i>
                                </span>
                            </IconButton>
                        }
                        sx={{ mb: 2, fontSize:'16px', borderRadius:'10px', alignItems:'center'}}
                        severity="warning"
                        >
                            All fields are required
                        </Alert>
                    </Collapse>
                    <div className='cp-form-field-container'>
                        <TextField   
                            label='Name' 
                            variant='outlined'
                            disabled={inputsEnabler}
                            InputLabelProps={{ style: { color: '#AEB1B4', fontFamily:'poppins', fontSize: 15  }}}
                            inputProps={{style: {fontSize: 15, fontFamily:'poppins'}}}
                            onChange={(event) => {
                                setName(event.target.value)
                            }}
                            value={name}
                            sx={{
                            width: '500px', 
                            backgroundColor: 'none', 
                            borderRadius: '10px',
                            "& fieldset": { border: 'none' }
                            }}
                        />
                        <span className='topbar-searchbar-icon' style={{color:'#CECECE', paddingRight:'30px'}}>
                            <i class="fa-solid fa-wallet fa-sm"></i>
                        </span>
                    </div>
                    <div className='cp-form-field-container'>
                        <TextField   
                            label='Description' 
                            variant='outlined'
                            disabled={inputsEnabler}
                            InputLabelProps={{ style: { color: '#AEB1B4', fontFamily:'poppins', fontSize: 15  }}}
                            inputProps={{style: {fontSize: 15, fontFamily:'poppins'}}}
                            onChange={(event) => {
                                setDescription(event.target.value)
                            }}
                            value={description}
                            sx={{
                            width: '500px', 
                            backgroundColor: 'none', 
                            borderRadius: '10px',
                            "& fieldset": { border: 'none' }
                            }}
                        />
                        <span className='topbar-searchbar-icon' style={{color:'#CECECE', paddingRight:'30px'}}>
                            <i class="fa-solid fa-bars-staggered fa-sm"></i>
                        </span>
                    </div>
                    <div className='cp-custom-form-field-container' style={{fontSize: '15px'}}>
                        Include Volume
                        <Switch
                            checked={includeVolume}
                            disabled={inputsEnabler}
                            onCheckedChange={(checked) => {
                                if(checked === false){
                                    setIncludePrice(false)
                                }
                                setIncludeVolume(checked);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                    <div className='cp-custom-form-field-container' style={{fontSize: '15px'}}>
                        Include average price
                        <Switch
                            disabled={includeVolume === false ? true : false}
                            checked={includePrice}
                            onCheckedChange={(checked) => {
                                setIncludePrice(checked);
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                </div>

                <div className='cp-stock-list-container'>
                    <div className='cp-stock-list-container-padding-helper'>
                        <div className='cp-add-sotck-top-bar'>
                            <div className='portfolio-opportunities-toggle-title'>
                                Stocks added
                            </div>
                            <div className='cp-stock-count'>
                                {stocks.length}
                            </div>
                            {
                                inputsEnabler === true ? null : 
                                (
                                    <div className='cp-add-stock-button' aria-describedby={id} onClick={handleClick}>
                                        <span style={{fontSize:'13px', marginRight:'10px'}}>
                                            <i className="fas fa-plus"></i>
                                        </span>
                                        <div>
                                            Add Stock
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className='cp-stocks-table-container'>
                            <div className='portfolio-table-table'>
                                <DataGrid
                                rows={stocks}
                                columns={
                                    includeVolume === true && includePrice === true ? tableColumnOptions.kpisVolumePrice
                                    :
                                    includeVolume === true && includePrice === false ? tableColumnOptions.kpisVolume
                                    :
                                    includeVolume === false && includePrice === true ? tableColumnOptions.kpisPrice
                                    :
                                    tableColumnOptions.kpis
                                }
                                stickyHeader
                                rowHeight={65}
                                sx={{ 
                                    maxHeight: 'calc(100vh - 300px)',
                                    maxWidth:'calc(100vw - 7px)',
                                    fontFamily:'poppins', 
                                    overflowX: 'scroll',
                                    '&, [class^=MuiDataGrid]': { 
                                    borderLeft: 'none', 
                                    borderTop: 'none', 
                                    borderRight:'none' 
                                    }  
                                }}
                                initialState={{
                                    pagination: {
                                    paginationModel: { page: 0, pageSize: 10 },
                                    },
                                }}
                                pageSizeOptions={[5, 10, 30]}
                                />
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
  )
}

export default CreatePortfolio