import React, {useState, useRef, useEffect} from 'react'
import { SupabaseUserAuth } from '../context/SupabaseAuthContext';
import { Skeleton } from '@mui/material';
import { Link } from 'react-router-dom';
import ThesisCard from '../customComponents/ThesisCard';
import '../styles/Portfolio.css'


const Thesis = () => {

  const { session } = SupabaseUserAuth();

  const [thesis, setThesis] = useState([
    {
      id: 1,
      name: 'The Future of the Metaverse',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 5,
      type: "weighted",
      active: true,
      rating: 4.5,
      icon: 'fa-solid fa-arrow-trend-up',
      tag: 'Value'
    },
    {
      id: 2,
      name: 'The Global Stock Market',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 7,
      type: "weighted",
      active: true,
      rating: 3,
      icon: 'fa-solid fa-rocket',
      tag: 'Growth'
    },
    {
      id: 3,
      name: 'Growing the Economy',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 12,
      type: "weighted",
      active: true,
      rating: 5,
      icon: 'fa-solid fa-anchor',
      tag: 'Value'
    
    },
    {
      id: 1,
      name: 'The Future of the Metaverse',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 5,
      type: "weighted",
      active: true,
      rating: 4.5,
      icon: 'fa-solid fa-arrow-trend-up',
      tag: 'Value'
    },
    {
      id: 2,
      name: 'The Global Stock Market',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 7,
      type: "weighted",
      active: true,
      rating: 3,
      icon: 'fa-solid fa-rocket',
      tag: 'Growth'
    },
    {
      id: 3,
      name: 'Growing the Economy',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 12,
      type: "weighted",
      active: true,
      rating: 5,
      icon: 'fa-solid fa-anchor',
      tag: 'Value'
    
    },
    {
      id: 1,
      name: 'The Future of the Metaverse',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 5,
      type: "weighted",
      active: true,
      rating: 4.5,
      icon: 'fa-solid fa-arrow-trend-up',
      tag: 'Value'
    },
    {
      id: 2,
      name: 'The Global Stock Market',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 7,
      type: "weighted",
      active: true,
      rating: 3,
      icon: 'fa-solid fa-rocket',
      tag: 'Growth'
    },
    {
      id: 3,
      name: 'Growing the Economy',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 12,
      type: "weighted",
      active: true,
      rating: 5,
      icon: 'fa-solid fa-anchor',
      tag: 'Value'
    
    },
    {
      id: 1,
      name: 'The Future of the Metaverse',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 5,
      type: "weighted",
      active: true,
      rating: 4.5,
      icon: 'fa-solid fa-arrow-trend-up',
      tag: 'Value'
    },
    {
      id: 2,
      name: 'The Global Stock Market',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 7,
      type: "weighted",
      active: true,
      rating: 3,
      icon: 'fa-solid fa-rocket',
      tag: 'Growth'
    },
    {
      id: 3,
      name: 'Growing the Economy',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 12,
      type: "weighted",
      active: true,
      rating: 5,
      icon: 'fa-solid fa-anchor',
      tag: 'Value'
    
    },
    {
      id: 1,
      name: 'The Future of the Metaverse',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 5,
      type: "weighted",
      active: true,
      rating: 4.5,
      icon: 'fa-solid fa-arrow-trend-up',
      tag: 'Value'
    },
    {
      id: 2,
      name: 'The Global Stock Market',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 7,
      type: "weighted",
      active: true,
      rating: 3,
      icon: 'fa-solid fa-rocket',
      tag: 'Growth'
    },
    {
      id: 3,
      name: 'Growing the Economy',
      description: 'This thesis is about the future of the Metaverse and how it will change the world',
      totalParams: 12,
      type: "weighted",
      active: true,
      rating: 5,
      icon: 'fa-solid fa-anchor',
      tag: 'Value'
    
    }
  ]);

  useEffect(() => {


  }, [session]);

  return (
    <div className='portfolio-container'>
      <div className='portfolio-padding-helper' style={{overflow: 'scroll', height: '100%'}}>

        <div className='portfolio-topbar-container'>
          <div className='portfolio-general-title'>
            Thesis Collection
          </div>

          <div className='portfolio-vertical-spacer' style={{marginRight: '5px'}}></div>

          <Link to={'/Home/CreateThesis'}>
            <div className='portfolio-add-stock-button'>
              <span style={{fontSize:'11px', marginRight:'10px'}}>
                <i className="fas fa-plus"></i>
              </span>
              <div>
                New Thesis
              </div>
            </div>
          </Link>
        </div>

        <div className='flex flex-col w-full h-3 mt-7'>
          {
            thesis != null && thesis.length > 0 ? (
              <div>
                {
                  thesis.map((item, index) => (
                    <ThesisCard key={index} thesis={item} />
                  ))
                }
              </div>
            )
            : thesis != null && thesis.length == 0 ? (
              <div>
                No thesis created yet
              </div>
            )
            :(
              <div className='flex flex-col w-full h-3'>
                <div className='flex flex-row w-full h-3'>
                  <div className='flex flex-col w-1/3 h-3'>
                    <Skeleton variant="rectangular" width={'100%'} height={100} />
                  </div>
                  <div className='flex flex-col w-1/3 h-3'>
                    <Skeleton variant="rectangular" width={'100%'} height={100} />
                  </div>
                  <div className='flex flex-col w-1/3 h-3'>
                    <Skeleton variant="rectangular" width={'100%'} height={100} />
                  </div>
                </div>
              </div>
            )
              
          }
        </div>
        
      </div>
    </div>
  )
}

export default Thesis