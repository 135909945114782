import React from 'react'

const ValueIndexCell = ({params}) => {

    const paramValue = params.value
    const rowData = params.row


  return (
    <div>
        {
            paramValue >= 70 && paramValue < 85 ? (
                <div style={{color:'#5A67BA', fontWeight:'600'}}>
                    {paramValue}
                </div>
            )
            :
            paramValue >= 85 ? (
                <div style={{color:'#24991A', fontWeight:'600'}}>
                    {paramValue}
                </div>
            )
            :
            paramValue <= 50 ? (
                <div style={{color:'#E07474', fontWeight:'600'}}>
                    {paramValue}
                </div>
            )
            :
            (
                <div style={{color:'black'}}>
                    {paramValue}
                </div>
               
            )
        }
    </div>
  )
}

export default ValueIndexCell