import React from 'react'

const Cell = ({params}) => {

    const paramValue = params.value.value
    const paramInfo = params.value
    const rowData = params.row

  return (
    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
        {
            paramInfo.polarity === 'asc' && paramInfo.polaritySentiment === 'good'? (
                <span>
                    <i className="fas fa-chevron-up" style={{color:'#149D52', marginRight:'5px', fontSize:'11px'}}></i>
                </span>
            )
            :
            paramInfo.polarity === 'asc' && paramInfo.polaritySentiment === 'bad'? (
                <span>
                    <i className="fas fa-chevron-up" style={{color:'#EC5252', marginRight:'5px', fontSize:'11px'}}></i>
                </span>
            )
            :
            paramInfo.polarity === 'desc' && paramInfo.polaritySentiment === 'good'? (
                <span>
                    <i className="fas fa-chevron-down" style={{color:'#149D52', marginRight:'5px', fontSize:'11px'}}></i>
                </span>
            )
            :
            paramInfo.polarity === 'desc' && paramInfo.polaritySentiment === 'bad'? (
                <span>
                    <i className="fas fa-chevron-down" style={{color:'#EC5252', marginRight:'5px', fontSize:'11px'}}></i>
                </span>
            )
            :
            null
        }

        <div>
            {paramValue + ' ' + paramInfo.unit}
        </div>

        <div style={{display:'flex', alignItems:'center', height:'100%'}}>
            {
                paramInfo.indexCont === 'high' ? (
                    <span>
                        <i className="fa-solid fa-circle" style={{color:'#149D52', marginLeft:'5px', fontSize:'6px', paddingBottom:'9px', marginLeft:'8px'}}></i>
                    </span>
                )
                :
                paramInfo.indexCont === 'med' ? (
                    <span>
                        <i className="fa-solid fa-circle" style={{color:'#5A67BA', marginLeft:'5px', fontSize:'6px', paddingBottom:'9px', marginLeft:'8px'}}></i>
                    </span>
                )
                :
                null
            }
        </div>
        
    </div>
  )
}

export default Cell