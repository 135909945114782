import React, {useState} from 'react'
import '../styles/Login.css'
import { TextField } from '@mui/material'
import { Button } from '@mui/material'
import { Link, Navigate } from 'react-router-dom'
import { SupabaseUserAuth } from '../context/SupabaseAuthContext'
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import { Alert } from '@mui/material';
import logo from '../assets/logo.png'
import { Input } from "../components/ui/input"



const Login = () => {

    const { session, supabaseMailSignIn } = SupabaseUserAuth()

    const [mail, setMail] = useState('')
    const [password, setPassword] = useState('')

    const [open, setOpen] = useState(false);

    const updateMail = (event) =>{
        setMail(event.target.value)
    }

    const updatePassword = (event) =>{
        setPassword(event.target.value)
    }

    const handleLogin = async () => {
        if(mail != '' || password != ''){

            try {
                //Sign in supabase
                const supabaseCall = await supabaseMailSignIn(mail, password)
                console.log(supabaseCall)
                //End sign in supabase

                if(supabaseCall.user === null){
                    setOpen(true)
                }
                
            } catch (error) {
                console.log('error supabase' + error)
            }

        }else{
            setOpen(true)
        }
    }

    if(session !== null){
        return <Navigate to={'/Home/Dashboard'}/>
    }

  return (
    <div className='log-container'>
        <div className='log-left-zone'>
            <div>

            </div>
        </div>
        <div className='log-right-zone'>
            <div className='log-logo-container'>
                <div className='log-title'>
                    <img src={logo} alt='logo' className='h-9 mb-8'/>
                </div>        
            </div>
            <Collapse in={open}>
                <Alert
                action={
                    <IconButton
                    aria-label="close"
                    color="inherit"
                    size="medium"
                    onClick={() => {
                        setOpen(false);
                    }}
                    >
                        <span>
                            <i class="fa-solid fa-xmark"></i>
                        </span>
                    </IconButton>
                }
                sx={{ mb: 2, fontSize:'16px', borderRadius:'10px', alignItems:'center'}}
                severity="warning"
                >
                    Wrong credentials
                </Alert>
            </Collapse>
            <div className='log-form'>
                <div className='log-textfield'>
                    <Input
                        type="email" 
                        placeholder="Email"
                        value={mail}
                        onChange={(event)=> updateMail(event)}
                        className='w-72 h-10'
                    />
                </div>
                <div className='log-textfield'>
                    <Input
                        type="password" 
                        placeholder="Password"
                        value={password}
                        onChange={(event)=> updatePassword(event)}
                        className='w-72 h-10'
                    />
                </div>
                <div>
                    <Button 
                        className='log-button' 
                        onClick={()=> handleLogin()}
                        sx={{
                            backgroundColor:'#5A67BA', 
                            textTransform:'none', 
                            borderRadius: '20px', 
                            minWidth: '120px', 
                            height: '40px', 
                            marginTop: '15px', 
                            fontSize: '15px', 
                            fontFamily:'poppins'
                        }} 
                        variant='contained'
                    >
                        Sign in
                    </Button>
                </div>         
            </div>
            <div className='log-create-account-container'>
                <div className='log-create-account-text'>Don't have an account yet?</div>
                <Link to={'/Register'}>
                    <div className='log-create-account-link'>Create your account</div>
                </Link>
                
            </div>
        </div>
    </div>
  )
}

export default Login