import React from 'react'
import NewsCard from '../NewsCard'

const NewsTab = () => {
    const news = [
        {
            "symbol": "TSM",
            "publishedDate": "2023-10-19 10:38:47",
            "title": "Chip Stock Soars After Beat-and-Raise",
            "image": "https://cdn.snapi.dev/images/v1/k/j/138124333-m-normal-none-1981779-2111438.jpg",
            "site": "Schaeffers Research",
            "text": "Taiwan Semiconductor cuando wnuqd withe way. Taiwan Semiconductor cuando wnuqd withe way.",
            "url": "https://www.schaeffersresearch.com/content/news/2023/10/19/chip-stock-soars-after-beat-and-raise"
        },
        {
            "symbol": "TSM",
            "publishedDate": "2023-10-19 10:38:47",
            "title": "Chip Stock Soars After Beat-and-Raise",
            "image": "https://cdn.snapi.dev/images/v1/k/j/138124333-m-normal-none-1981779-2111438.jpg",
            "site": "Schaeffers Research",
            "text": "Taiwan Semiconductor cuando wnuqd withe way. Taiwan Semiconductor cuando wnuqd withe way.",
            "url": "https://www.schaeffersresearch.com/content/news/2023/10/19/chip-stock-soars-after-beat-and-raise"
        },
        {
            "symbol": "TSM",
            "publishedDate": "2023-10-19 10:38:47",
            "title": "Chip Stock Soars After Beat-and-Raise",
            "image": "https://cdn.snapi.dev/images/v1/k/j/138124333-m-normal-none-1981779-2111438.jpg",
            "site": "Schaeffers Research",
            "text": "Taiwan Semiconductor cuando wnuqd withe way. Taiwan Semiconductor cuando wnuqd withe way.",
            "url": "https://www.schaeffersresearch.com/content/news/2023/10/19/chip-stock-soars-after-beat-and-raise"
        },
        {
            "symbol": "TSM",
            "publishedDate": "2023-10-19 10:38:47",
            "title": "Chip Stock Soars After Beat-and-Raise",
            "image": "https://cdn.snapi.dev/images/v1/k/j/138124333-m-normal-none-1981779-2111438.jpg",
            "site": "Schaeffers Research",
            "text": "Taiwan Semiconductor cuando wnuqd withe way. Taiwan Semiconductor cuando wnuqd withe way.",
            "url": "https://www.schaeffersresearch.com/content/news/2023/10/19/chip-stock-soars-after-beat-and-raise"
        }
    ]
  return (
    <div className='flex flex-row flex-wrap pt-4'>
        {
            news.map((newsItem, index) => {
                return (
                    <NewsCard key={index} newsItem={newsItem} />
                )
            })
        }
    </div>
  )
}

export default NewsTab