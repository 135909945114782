import React from 'react'
import { format } from 'date-fns';

const NewsCard = ({newsItem}) => {

    const formattedDate = format(new Date(newsItem.publishedDate), 'h:mm | dd MMM yy');

    const handleClick = () => {
        window.open(newsItem.url, '_blank');
    }

  return (
    <div className='group flex flex-row mr-6 mb-6 border rounded-2xl p-1.5 min-h-32 cursor-pointer transform' onClick={() => {handleClick()}}>
        <div className='flex rounded-l-lg mr-3'>
            <img className='rounded-l-xl h-full max-w-28 object-cover object-center' src={newsItem.image} alt={newsItem.title} />
        </div>
        <div className='mr-1.5'>
           <div className='flex flex-row justify-between items-start'>
                <div className='mr-12 text-sm font-medium max-w-40 group-hover:underline transition-transform duration-200'>
                    {newsItem.title}
                </div>
                <div className='text-xs text-gray-400 pt-0.5'>
                    {formattedDate}
                </div>
            </div> 
            <div className='mt-2.5 flex flex-col max-w-full justify-between'>
                <div className='text-xs text-gray-600 max-w-72'>
                    {
                        newsItem.text
                    
                    }
                </div>
                <div className='flex flex-row mt-4 items-center text-gray-400'>
                    <span className='mr-1'>
                        <i class="fa-regular fa-newspaper fa-xs"></i>
                    </span>
                    <div className='text-xs text-gray-400 ml-1'>
                        {newsItem.site}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewsCard