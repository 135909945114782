import React, { useState } from 'react'
import '../styles/OpportunityCard.css'

const OpportunityCard = ({opportunity}) => {

    const data = opportunity

  return (
    <div className='opcard-container'>
        <div className='opcard-padding-helper'>

            <div className='opcard-top'>
                <div className='opcard-top-stock-container'>
                    <div className='opcard-image-container'>
                        <img style={{maxWidth:'38px'}} src={data.logo}/>
                    </div>

                    <div className='opcard-tickr-name-container'>
                        <div className='opcard-name'>
                            {data.company}
                        </div>
                        <div className='opcard-tikr'>
                            {data.ticker}
                        </div>
                    </div>
                </div>
                <div className='opcard-thesis-container'>
                    <div className='opcard-thesis-name'>
                        <span style={{marginRight:'8px'}}>
                            <i class="fa-solid fa-swatchbook"></i>
                        </span>
                        {data.basedThesis}
                    </div>
                    <div className='opcard-index'>
                        {data.valueIndex}
                    </div>
                </div>
            </div>

            <div className='opcard-bottom'>

                <div className='opcard-kpis-column' style={{marginRight:'25px'}}>
                    {
                        data.mainKPIS.map((kpi, index) => {
                            return (
                                <div key={index}>
                                    {
                                        index <= 1 ? (
                                            <div className='opcard-kpi-container'>
                                                <div className='opcard-kpi-name'>
                                                    {kpi.kpi}
                                                </div>
            
                                                <div className='opcard-kpi-name-container'>
                                                    <div className='opcard-kpi-polarity'>
                                                        {
                                                            kpi.polarity === 'asc' && kpi.polaritySentiment === 'good'? (
                                                                <span>
                                                                    <i className="fas fa-chevron-up" style={{color:'#149D52', marginRight:'5px', fontSize:'10px'}}></i>
                                                                </span>
                                                            )
                                                            :
                                                            kpi.polarity === 'asc' && kpi.polaritySentiment === 'bad'? (
                                                                <span>
                                                                    <i className="fas fa-chevron-up" style={{color:'#EC5252', marginRight:'5px', fontSize:'10px'}}></i>
                                                                </span>
                                                            )
                                                            :
                                                            kpi.polarity === 'desc' && kpi.polaritySentiment === 'good'? (
                                                                <span>
                                                                    <i className="fas fa-chevron-down" style={{color:'#149D52', marginRight:'5px', fontSize:'10px'}}></i>
                                                                </span>
                                                            )
                                                            :
                                                            kpi.polarity === 'desc' && kpi.polaritySentiment === 'bad'? (
                                                                <span>
                                                                    <i className="fas fa-chevron-down" style={{color:'#EC5252', marginRight:'5px', fontSize:'10px'}}></i>
                                                                </span>
                                                            )
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <div className='opcard-kpi-value'>
                                                        {
                                                            kpi.value
                                                        }
                                                        {
                                                            kpi.unit != null ? ' ' + kpi.unit : null
                                                        }
                                                    </div>
                                                    <div className='opcard-kpi-index' style={{display:'flex', alignItems:'center'}}>
                                                        {
                                                            kpi.indexCont === 'high' ? (
                                                                <span style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                                    <i className="fa-solid fa-circle" style={{color:'#81C5AC', fontSize:'6px', marginLeft:'8px'}}></i>
                                                                </span>
                                                            )
                                                            :
                                                            kpi.indexCont === 'med' ? (
                                                                <span>
                                                                    <i className="fa-solid fa-circle" style={{color:'#5A67BA', fontSize:'6px', marginLeft:'8px'}}></i>
                                                                </span>
                                                            )
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        :
                                        null

                                    }
                                </div>
                            )
                        })
                    }

                </div>

                <div className='opcard-kpis-column'>
                    {
                        data.mainKPIS.map((kpi, index) => {
                            return (
                                <div key={index}>
                                    {
                                        index >= 2 ? (
                                            <div className='opcard-kpi-container'>
                                                <div className='opcard-kpi-name'>
                                                    {kpi.kpi}
                                                </div>
            
                                                <div className='opcard-kpi-name-container'>
                                                    <div className='opcard-kpi-polarity'>
                                                        {
                                                            kpi.polarity === 'asc' && kpi.polaritySentiment === 'good'? (
                                                                <span>
                                                                    <i className="fas fa-chevron-up" style={{color:'#149D52', marginRight:'5px', fontSize:'10px'}}></i>
                                                                </span>
                                                            )
                                                            :
                                                            kpi.polarity === 'asc' && kpi.polaritySentiment === 'bad'? (
                                                                <span>
                                                                    <i className="fas fa-chevron-up" style={{color:'#EC5252', marginRight:'5px', fontSize:'10px'}}></i>
                                                                </span>
                                                            )
                                                            :
                                                            kpi.polarity === 'desc' && kpi.polaritySentiment === 'good'? (
                                                                <span>
                                                                    <i className="fas fa-chevron-down" style={{color:'#149D52', marginRight:'5px', fontSize:'10px'}}></i>
                                                                </span>
                                                            )
                                                            :
                                                            kpi.polarity === 'desc' && kpi.polaritySentiment === 'bad'? (
                                                                <span>
                                                                    <i className="fas fa-chevron-down" style={{color:'#EC5252', marginRight:'5px', fontSize:'10px'}}></i>
                                                                </span>
                                                            )
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <div className='opcard-kpi-value'>
                                                        {
                                                            kpi.value
                                                        }
                                                        {
                                                            kpi.unit != null ? ' ' + kpi.unit : null
                                                        }
                                                    </div>
                                                    <div className='opcard-kpi-index' style={{display:'flex', alignItems:'center'}}>
                                                        {
                                                            kpi.indexCont === 'high' ? (
                                                                <span style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                                    <i className="fa-solid fa-circle" style={{color:'#81C5AC', fontSize:'6px', marginLeft:'8px'}}></i>
                                                                </span>
                                                            )
                                                            :
                                                            kpi.indexCont === 'med' ? (
                                                                <span>
                                                                    <i className="fa-solid fa-circle" style={{color:'#5A67BA', fontSize:'6px', marginLeft:'8px'}}></i>
                                                                </span>
                                                            )
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                        :
                                        null

                                    }
                                </div>
                            )
                        })
                    }

                </div>
            </div>

        </div>
    </div>
  )
}

export default OpportunityCard