import React from 'react'
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { useState, useEffect } from 'react'
import RatioCell from '../customCells/RatioCell';
import LargeDigitCell from '../customCells/LargeDigitCell';
import SmallDigitCell from '../customCells/SmallDigitCell';


const FinancialsTab = ({stockData, selectedPeriods}) => {

    const [screenData, setScreenData] = useState({
        metrics:[
        {
            id: 1,
            metricKey: 'TTMEnterpriseValueDIVRevenues',
            metricDisplayName: 'TTM EV / Revenue',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 3,
            metricKey: 'TTMTotalEnterpriseValueDIVGrossProfit',
            metricDisplayName: 'TTM EV / Gross Profit',
            metricSufix: 'x',
            values: { 2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 4,
            metricKey: 'TTMTotalEnterpriseValueDIVEBITDA',
            metricDisplayName: 'TTM EV / EBITDA',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 5,
            metricKey: 'TTMTotalEnterpriseValueDIVEBIT',
            metricDisplayName: 'TTM EV / EBIT',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 6,
            metricKey: 'TTMTotalEnterpriseValueDIVUnleveredFreeCashFlow',
            metricDisplayName: 'TTM EV / Unlevered Free Cash Flow',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 2,
            metricKey: 'TTMPriceDIVSales',
            metricDisplayName: 'TTM Price / Sales',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 7,
            metricKey: 'TTMPriceDIVDilutedEPS',
            metricDisplayName: 'TTM Price / Diluted EPS',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 8,
            metricKey: 'TTMPriceDIVBookValuePerShare',
            metricDisplayName: 'TTM Price / Book Value Per Share',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 9,
            metricKey: 'TTMPriceDIVTangibleBookValuePerShare',
            metricDisplayName: 'TTM Price / Tangible Book Value Per Share',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 11,
            metricKey: 'TTMPriceDIVNetCurrentAssetValue',
            metricDisplayName: 'TTM Price / Net Current Asset Value',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 10,
            metricKey: 'TTMMarketCapDIVLeveredFreeCashFlow',
            metricDisplayName: 'TTM Market Cap / Levered Free Cash Flow',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 12,
            metricKey: 'TTMDividendYield',
            metricDisplayName: 'TTM Dividend Yield',
            metricSufix: '%',
            values: {2019: 0.5678,
            2020: 0.5678,
            2021: 0.5678,
            2022: 0.5678,}
        }
        ],
        periods:[]
    })

    const [statementsDatesFormated, setStatementsDatesFormated] = useState({
        income: [
            {
                "id": "revenue",
                "metricKey": "revenue",
                "metricDisplayName": "Revenue",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "boldIncome",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 260174000000,
                    "2020": 274515000000,
                    "2021": 365817000000,
                    "2022": 394328000000,
                    "2023": 383285000000
                },
                "growth": {
                    "2019": 0,
                    "2020": 0.055,
                    "2021": 0.333,
                    "2022": 0.078,
                    "2023": -0.028
                }
            },
            {
                "id": "costOfRevenue",
                "metricKey": "costOfRevenue",
                "metricDisplayName": "Cost of Revenue",
                "cellFormat": "LargeDigitCell",
                "redNumber": true,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 161782000000,
                    "2020": 169559000000,
                    "2021": 212981000000,
                    "2022": 223546000000,
                    "2023": 214137000000
                }
            },
            {
                "id": "grossProfit",
                "metricKey": "grossProfit",
                "metricDisplayName": "Gross Profit",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "totalIncome",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 98392000000,
                    "2020": 104956000000,
                    "2021": 152836000000,
                    "2022": 170782000000,
                    "2023": 169148000000
                },
                "growth": {
                    "2019": 0,
                    "2020": 0.065,
                    "2021": 0.457,
                    "2022": 0.117,
                    "2023": -0.009
                }
            },
            {
                "id": "grossProfitRatio",
                "metricKey": "grossProfitRatio",
                "metricDisplayName": "Gross Profit Ratio",
                "cellFormat": "RatioCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": true,
                "values": {
                    "2019": 0.3781776811,
                    "2020": 0.3823324773,
                    "2021": 0.4177935963,
                    "2022": 0.4330963056,
                    "2023": 0.4413112958
                }
            },
            {
                "id": "sellingGeneralAndAdministrativeExpenses",
                "metricKey": "sellingGeneralAndAdministrativeExpenses",
                "metricDisplayName": "Selling, General and Administrative Expenses",
                "cellFormat": "LargeDigitCell",
                "redNumber": true,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 18245000000,
                    "2020": 19916000000,
                    "2021": 21973000000,
                    "2022": 25094000000,
                    "2023": 24932000000
                }
            },
            {
                "id": "researchAndDevelopmentExpenses",
                "metricKey": "researchAndDevelopmentExpenses",
                "metricDisplayName": "Research and Development Expenses",
                "cellFormat": "LargeDigitCell",
                "redNumber": true,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 16217000000,
                    "2020": 18752000000,
                    "2021": 21914000000,
                    "2022": 26251000000,
                    "2023": 29915000000
                }
            },
            {
                "id": "operatingExpenses",
                "metricKey": "operatingExpenses",
                "metricDisplayName": "Operating Expenses",
                "cellFormat": "LargeDigitCell",
                "redNumber": true,
                "rowType": "boldIncome",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 34462000000,
                    "2020": 38668000000,
                    "2021": 43887000000,
                    "2022": 51345000000,
                    "2023": 54847000000
                }
            },
            {
                "id": "operatingIncome",
                "metricKey": "operatingIncome",
                "metricDisplayName": "Operating Income",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "totalIncome",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 63930000000,
                    "2020": 66288000000,
                    "2021": 108949000000,
                    "2022": 119437000000,
                    "2023": 114301000000
                },
                "growth": {
                    "2019": 0,
                    "2020": 0.036,
                    "2021": 0.643,
                    "2022": 0.097,
                    "2023": -0.043
                }
            },
            {
                "id": "operatingIncomeRatio",
                "metricKey": "operatingIncomeRatio",
                "metricDisplayName": "Operating Income Ratio",
                "cellFormat": "RatioCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": true,
                "values": {
                    "2019": 0.2457201719,
                    "2020": 0.2414731435,
                    "2021": 0.2978237753,
                    "2022": 0.302887444,
                    "2023": 0.2982141227
                }
            },
            {
                "id": "interestExpense",
                "metricKey": "interestExpense",
                "metricDisplayName": "Interest Expense",
                "cellFormat": "LargeDigitCell",
                "redNumber": true,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 3576000000,
                    "2020": 2873000000,
                    "2021": 2645000000,
                    "2022": 2931000000,
                    "2023": 3933000000
                }
            },
            {
                "id": "interestIncome",
                "metricKey": "interestIncome",
                "metricDisplayName": "Interest Income",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 4961000000,
                    "2020": 3763000000,
                    "2021": 2843000000,
                    "2022": 2825000000,
                    "2023": 3750000000
                }
            },
            {
                "id": "incomeBeforeTax",
                "metricKey": "incomeBeforeTax",
                "metricDisplayName": "Income Before Tax",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "totalIncome",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 65737000000,
                    "2020": 67091000000,
                    "2021": 109207000000,
                    "2022": 119103000000,
                    "2023": 113736000000
                }
            },
            {
                "id": "incomeBeforeTaxRatio",
                "metricKey": "incomeBeforeTaxRatio",
                "metricDisplayName": "Income Before Tax Ratio",
                "cellFormat": "RatioCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": true,
                "values": {
                    "2019": 0.2526655238,
                    "2020": 0.2443983025,
                    "2021": 0.2985290459,
                    "2022": 0.3020404333,
                    "2023": 0.2967400237
                }
            },
            {
                "id": "incomeTaxExpense",
                "metricKey": "incomeTaxExpense",
                "metricDisplayName": "Income Tax Expense",
                "cellFormat": "LargeDigitCell",
                "redNumber": true,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 10481000000,
                    "2020": 9680000000,
                    "2021": 14527000000,
                    "2022": 19300000000,
                    "2023": 16741000000
                }
            },
            {
                "id": "netIncome",
                "metricKey": "netIncome",
                "metricDisplayName": "Net Income",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "totalIncome",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 55256000000,
                    "2020": 57411000000,
                    "2021": 94680000000,
                    "2022": 99803000000,
                    "2023": 96995000000
                },
                "growth": {
                    "2019": 0,
                    "2020": 0.039,
                    "2021": 0.650,
                    "2022": 0.053,
                    "2023": -0.028
                }
            },
            {
                "id": "netIncomeRatio",
                "metricKey": "netIncomeRatio",
                "metricDisplayName": "Net Income Ratio",
                "cellFormat": "RatioCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": true,
                "values": {
                    "2019": 0.2123809451,
                    "2020": 0.2091361128,
                    "2021": 0.2588179336,
                    "2022": 0.2530964071,
                    "2023": 0.2530623426
                }
            },
            {
                "id": "eps",
                "metricKey": "eps",
                "metricDisplayName": "EPS",
                "cellFormat": "SmallDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 2.99,
                    "2020": 3.31,
                    "2021": 5.67,
                    "2022": 6.15,
                    "2023": 6.16
                }
            },
            {
                "id": "epsdiluted",
                "metricKey": "epsdiluted",
                "metricDisplayName": "EPS Diluted",
                "cellFormat": "SmallDigitCell",
                "redNumber": false,
                "rowType": "boldIncome",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 2.97,
                    "2020": 3.28,
                    "2021": 5.61,
                    "2022": 6.11,
                    "2023": 6.13
                },
                "growth": {
                    "2019": 0,
                    "2020": 0.034,
                    "2021": 0.712,
                    "2022": 0.089,
                    "2023": 0.003
                }
            },
            {
                "id": "weightedAverageShsOut",
                "metricKey": "weightedAverageShsOut",
                "metricDisplayName": "Weighted Average Shs Out",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 18471336000,
                    "2020": 17352119000,
                    "2021": 16701272000,
                    "2022": 16215963000,
                    "2023": 15744231000
                }
            },
            {
                "id": "weightedAverageShsOutDil",
                "metricKey": "weightedAverageShsOutDil",
                "metricDisplayName": "Weighted Average Shs Out Dil",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 18595652000,
                    "2020": 17528214000,
                    "2021": 16864919000,
                    "2022": 16325819000,
                    "2023": 15812547000
                }
            },
            {
                "id": "ebitda",
                "metricKey": "ebitda",
                "metricDisplayName": "EBITDA",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 76477000000,
                    "2020": 77344000000,
                    "2021": 120233000000,
                    "2022": 130541000000,
                    "2023": 125820000000
                },
                "growth": {
                    "2019": 0,
                    "2020": 0.011,
                    "2021": 0.554,
                    "2022": 0.086,
                    "2023": -0.036
                }
            },
            {
                "id": "ebitdaratio",
                "metricKey": "ebitdaratio",
                "metricDisplayName": "EBITDA Ratio",
                "cellFormat": "RatioCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": true,
                "values": {
                    "2019": 0.2939455903,
                    "2020": 0.2817478098,
                    "2021": 0.3286697994,
                    "2022": 0.3310467428,
                    "2023": 0.3282674772
                }
            }
        ],
        balance: [
            {
                "id": "cashAndCashEquivalents",
                "metricKey": "cashAndCashEquivalents",
                "metricDisplayName": "Cash and Cash Equivalents",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 48844000000,
                    "2020": 38016000000,
                    "2021": 34940000000,
                    "2022": 23646000000,
                    "2023": 29965000000
                }
            },
            {
                "id": "shortTermInvestments",
                "metricKey": "shortTermInvestments",
                "metricDisplayName": "Short Term Investments",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 51713000000,
                    "2020": 52927000000,
                    "2021": 27699000000,
                    "2022": 24658000000,
                    "2023": 31590000000
                }
            },
            {
                "id": "cashAndShortTermInvestments",
                "metricKey": "cashAndShortTermInvestments",
                "metricDisplayName": "Cash and Short Term Investments",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "bold",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 100557000000,
                    "2020": 90943000000,
                    "2021": 62639000000,
                    "2022": 48304000000,
                    "2023": 61555000000
                }
            },
            {
                "id": "netReceivables",
                "metricKey": "netReceivables",
                "metricDisplayName": "Net Receivables",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "bold",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 45804000000,
                    "2020": 37445000000,
                    "2021": 51506000000,
                    "2022": 60932000000,
                    "2023": 60985000000
                }
            },
            {
                "id": "inventory",
                "metricKey": "inventory",
                "metricDisplayName": "Inventory",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 4106000000,
                    "2020": 4061000000,
                    "2021": 6580000000,
                    "2022": 4946000000,
                    "2023": 6331000000
                }
            },
            {
                "id": "otherCurrentAssets",
                "metricKey": "otherCurrentAssets",
                "metricDisplayName": "Other Current Assets",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 12352000000,
                    "2020": 11264000000,
                    "2021": 14111000000,
                    "2022": 21223000000,
                    "2023": 14695000000
                }
            },
            {
                "id": "totalCurrentAssets",
                "metricKey": "totalCurrentAssets",
                "metricDisplayName": "Total Current Assets",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "bold",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 162819000000,
                    "2020": 143713000000,
                    "2021": 134836000000,
                    "2022": 135405000000,
                    "2023": 143566000000
                }
            },
            {
                "id": "propertyPlantEquipmentNet",
                "metricKey": "propertyPlantEquipmentNet",
                "metricDisplayName": "Net Property Plant Equipment",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "bold",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 37378000000,
                    "2020": 36766000000,
                    "2021": 39440000000,
                    "2022": 42117000000,
                    "2023": 43715000000
                }
            },
            {
                "id": "goodwill",
                "metricKey": "goodwill",
                "metricDisplayName": "Goodwill",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 0,
                    "2020": 0,
                    "2021": 0,
                    "2022": 0,
                    "2023": 0
                }
            },
            {
                "id": "intangibleAssets",
                "metricKey": "intangibleAssets",
                "metricDisplayName": "Intangible Assets",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 0,
                    "2020": 0,
                    "2021": 0,
                    "2022": 0,
                    "2023": 0
                }
            },
            {
                "id": "goodwillAndIntangibleAssets",
                "metricKey": "goodwillAndIntangibleAssets",
                "metricDisplayName": "Goodwill and Intangible Assets",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "bold",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 0,
                    "2020": 0,
                    "2021": 0,
                    "2022": -15375000000,
                    "2023": -17852000000
                }
            },
            {
                "id": "longTermInvestments",
                "metricKey": "longTermInvestments",
                "metricDisplayName": "Long Term Investments",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 105341000000,
                    "2020": 100887000000,
                    "2021": 127877000000,
                    "2022": 120805000000,
                    "2023": 100544000000
                }
            },
            {
                "id": "taxAssets",
                "metricKey": "taxAssets",
                "metricDisplayName": "Tax Assets",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 0,
                    "2020": 0,
                    "2021": 0,
                    "2022": 15375000000,
                    "2023": 17852000000
                }
            },
            {
                "id": "otherNonCurrentAssets",
                "metricKey": "otherNonCurrentAssets",
                "metricDisplayName": "Other Non Current Assets",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 32978000000,
                    "2020": 42522000000,
                    "2021": 48849000000,
                    "2022": 54428000000,
                    "2023": 64758000000
                }
            },
            {
                "id": "totalNonCurrentAssets",
                "metricKey": "totalNonCurrentAssets",
                "metricDisplayName": "Total Non Current Assets",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "bold",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 175697000000,
                    "2020": 180175000000,
                    "2021": 216166000000,
                    "2022": 217350000000,
                    "2023": 209017000000
                }
            },
            {
                "id": "totalAssets",
                "metricKey": "totalAssets",
                "metricDisplayName": "Total Assets",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "totalBalance",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 338516000000,
                    "2020": 323888000000,
                    "2021": 351002000000,
                    "2022": 352755000000,
                    "2023": 352583000000
                }
            },
            {
                "id": "accountPayables",
                "metricKey": "accountPayables",
                "metricDisplayName": "Account Payables",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 46236000000,
                    "2020": 42296000000,
                    "2021": 54763000000,
                    "2022": 64115000000,
                    "2023": 62611000000
                }
            },
            {
                "id": "shortTermDebt",
                "metricKey": "shortTermDebt",
                "metricDisplayName": "Short Term Debt",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 16240000000,
                    "2020": 13769000000,
                    "2021": 15613000000,
                    "2022": 21110000000,
                    "2023": 15807000000
                }
            },
            {
                "id": "deferredRevenue",
                "metricKey": "deferredRevenue",
                "metricDisplayName": "Deferred Revenue",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 5522000000,
                    "2020": 6643000000,
                    "2021": 7612000000,
                    "2022": 7912000000,
                    "2023": 8061000000
                }
            },
            {
                "id": "otherCurrentLiabilities",
                "metricKey": "otherCurrentLiabilities",
                "metricDisplayName": "Other Current Liabilities",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 37720000000,
                    "2020": 42684000000,
                    "2021": 47493000000,
                    "2022": 60845000000,
                    "2023": 58829000000
                }
            },
            {
                "id": "totalCurrentLiabilities",
                "metricKey": "totalCurrentLiabilities",
                "metricDisplayName": "Total Current Liabilities",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "bold",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 105718000000,
                    "2020": 105392000000,
                    "2021": 125481000000,
                    "2022": 153982000000,
                    "2023": 145308000000
                }
            },
            {
                "id": "longTermDebt",
                "metricKey": "longTermDebt",
                "metricDisplayName": "Long Term Debt",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 91807000000,
                    "2020": 98667000000,
                    "2021": 109106000000,
                    "2022": 98959000000,
                    "2023": 95281000000
                }
            },
            {
                "id": "deferredTaxLiabilitiesNonCurrent",
                "metricKey": "deferredTaxLiabilitiesNonCurrent",
                "metricDisplayName": "Deferred Tax Liabilities Non Current",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 16919000000,
                    "2020": 17875000000,
                    "2021": 16977000000,
                    "2022": 16758000000,
                    "2023": 19454000000
                }
            },
            {
                "id": "otherNonCurrentLiabilities",
                "metricKey": "otherNonCurrentLiabilities",
                "metricDisplayName": "Other Non Current Liabilities",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 50503000000,
                    "2020": 54490000000,
                    "2021": 53325000000,
                    "2022": 49142000000,
                    "2023": 49848000000
                }
            },
            {
                "id": "totalNonCurrentLiabilities",
                "metricKey": "totalNonCurrentLiabilities",
                "metricDisplayName": "Total Non Current Liabilities",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 142310000000,
                    "2020": 153157000000,
                    "2021": 162431000000,
                    "2022": 148101000000,
                    "2023": 145129000000
                }
            },
            {
                "id": "otherLiabilities",
                "metricKey": "otherLiabilities",
                "metricDisplayName": "Other Liabilities",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 0,
                    "2020": 0,
                    "2021": 0,
                    "2022": 0,
                    "2023": 0
                }
            },
            {
                "id": "capitalLeaseObligations",
                "metricKey": "capitalLeaseObligations",
                "metricDisplayName": "Capital Lease Obligations",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 0,
                    "2020": 8382000000,
                    "2021": 10275000000,
                    "2022": 10748000000,
                    "2023": 11267000000
                }
            },
            {
                "id": "totalLiabilities",
                "metricKey": "totalLiabilities",
                "metricDisplayName": "Total Liabilities",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "totalBalance",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 248028000000,
                    "2020": 258549000000,
                    "2021": 287912000000,
                    "2022": 302083000000,
                    "2023": 290437000000
                }
            },
            {
                "id": "preferredStock",
                "metricKey": "preferredStock",
                "metricDisplayName": "Preferred Stock",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 0,
                    "2020": 0,
                    "2021": 0,
                    "2022": 0,
                    "2023": 0
                }
            },
            {
                "id": "commonStock",
                "metricKey": "commonStock",
                "metricDisplayName": "Common Stock",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 45174000000,
                    "2020": 50779000000,
                    "2021": 57365000000,
                    "2022": 64849000000,
                    "2023": 73812000000
                }
            },
            {
                "id": "retainedEarnings",
                "metricKey": "retainedEarnings",
                "metricDisplayName": "Retained Earnings",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 45898000000,
                    "2020": 14966000000,
                    "2021": 5562000000,
                    "2022": -3068000000,
                    "2023": -214000000
                }
            },
            {
                "id": "accumulatedOtherComprehensiveIncomeLoss",
                "metricKey": "accumulatedOtherComprehensiveIncomeLoss",
                "metricDisplayName": "Accumulated Other Comprehensive Income Loss",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -584000000,
                    "2020": -406000000,
                    "2021": 163000000,
                    "2022": -11109000000,
                    "2023": -11452000000
                }
            },
            {
                "id": "othertotalStockholdersEquity",
                "metricKey": "othertotalStockholdersEquity",
                "metricDisplayName": "Other Total Stockholders Equity",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 0,
                    "2020": 0,
                    "2021": 0,
                    "2022": 0,
                    "2023": 0
                }
            },
            {
                "id": "totalStockholdersEquity",
                "metricKey": "totalStockholdersEquity",
                "metricDisplayName": "Total Stockholders Equity",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "bold",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 90488000000,
                    "2020": 65339000000,
                    "2021": 63090000000,
                    "2022": 50672000000,
                    "2023": 62146000000
                }
            },
            {
                "id": "totalEquity",
                "metricKey": "totalEquity",
                "metricDisplayName": "Total Equity",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "bold",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 90488000000,
                    "2020": 65339000000,
                    "2021": 63090000000,
                    "2022": 50672000000,
                    "2023": 62146000000
                }
            },
            {
                "id": "totalLiabilitiesAndTotalEquity",
                "metricKey": "totalLiabilitiesAndTotalEquity",
                "metricDisplayName": "Total Liabilities and Total Equity",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "totalBalance",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 338516000000,
                    "2020": 323888000000,
                    "2021": 351002000000,
                    "2022": 352755000000,
                    "2023": 352583000000
                }
            },
            {
                "id": "totalDebt",
                "metricKey": "totalDebt",
                "metricDisplayName": "Total Debt",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 108047000000,
                    "2020": 112436000000,
                    "2021": 124719000000,
                    "2022": 120069000000,
                    "2023": 111088000000
                },
                "growth": {
                    "2019": 0,
                    "2020": 0.040,
                    "2021": 0.109,
                    "2022": -0.038,
                    "2023": -0.075
                }
            },
            {
                "id": "netDebt",
                "metricKey": "netDebt",
                "metricDisplayName": "Net Debt",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 59203000000,
                    "2020": 74420000000,
                    "2021": 89779000000,
                    "2022": 96423000000,
                    "2023": 81123000000
                },
                "growth": {
                    "2019": 0,
                    "2020": 0.257,
                    "2021": 0.206,
                    "2022": 0.074,
                    "2023": -0.157
                }
            }
        ],
        cash: [
            {
                "id": "netIncome",
                "metricKey": "netIncome",
                "metricDisplayName": "Net Income",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "boldIncome",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 55256000000,
                    "2020": 57411000000,
                    "2021": 94680000000,
                    "2022": 99803000000,
                    "2023": 96995000000
                }
            },
            {
                "id": "depreciationAndAmortization",
                "metricKey": "depreciationAndAmortization",
                "metricDisplayName": "Depreciation and Amortization",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "boldIncome",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 12547000000,
                    "2020": 11056000000,
                    "2021": 11284000000,
                    "2022": 11104000000,
                    "2023": 11519000000
                }
            },
            {
                "id": "stockBasedCompensation",
                "metricKey": "stockBasedCompensation",
                "metricDisplayName": "Stock Based Compensation",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 6068000000,
                    "2020": 6829000000,
                    "2021": 7906000000,
                    "2022": 9038000000,
                    "2023": 10833000000
                }
            },
            {
                "id": "changeInWorkingCapital",
                "metricKey": "changeInWorkingCapital",
                "metricDisplayName": "Change in Working Capital",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -3488000000,
                    "2020": 5690000000,
                    "2021": -4911000000,
                    "2022": 1200000000,
                    "2023": -6577000000
                }
            },
            {
                "id": "accountsReceivables",
                "metricKey": "accountsReceivables",
                "metricDisplayName": "Accounts Receivables",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 245000000,
                    "2020": 6917000000,
                    "2021": -10125000000,
                    "2022": -1823000000,
                    "2023": -1688000000
                }
            },
            {
                "id": "inventory",
                "metricKey": "inventory",
                "metricDisplayName": "Inventory",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -289000000,
                    "2020": -127000000,
                    "2021": -2642000000,
                    "2022": 1484000000,
                    "2023": -1618000000
                }
            },
            {
                "id": "accountsPayables",
                "metricKey": "accountsPayables",
                "metricDisplayName": "Accounts Payables",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -1923000000,
                    "2020": -4062000000,
                    "2021": 12326000000,
                    "2022": 9448000000,
                    "2023": -1889000000
                }
            },
            {
                "id": "otherWorkingCapital",
                "metricKey": "otherWorkingCapital",
                "metricDisplayName": "Other Working Capital",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -1521000000,
                    "2020": 2962000000,
                    "2021": -4470000000,
                    "2022": -7909000000,
                    "2023": 5195000000
                }
            },
            {
                "id": "otherNonCashItems",
                "metricKey": "otherNonCashItems",
                "metricDisplayName": "Other Non Cash Items",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -652000000,
                    "2020": -97000000,
                    "2021": -147000000,
                    "2022": 111000000,
                    "2023": -7422000000
                }
            },
            {
                "id": "netCashProvidedByOperatingActivities",
                "metricKey": "netCashProvidedByOperatingActivities",
                "metricDisplayName": "Net Cash Provided by Operating Activities",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "totalBalance",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 69391000000,
                    "2020": 80674000000,
                    "2021": 104038000000,
                    "2022": 122151000000,
                    "2023": 110543000000
                }
            },
            {
                "id": "capitalExpenditure",
                "metricKey": "capitalExpenditure",
                "metricDisplayName": "Capital Expenditure",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -10495000000,
                    "2020": -7309000000,
                    "2021": -11085000000,
                    "2022": -10708000000,
                    "2023": -10959000000
                }
            },
            {
                "id": "acquisitionsNet",
                "metricKey": "acquisitionsNet",
                "metricDisplayName": "Acquisitions Net",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -624000000,
                    "2020": -1524000000,
                    "2021": -33000000,
                    "2022": -306000000,
                    "2023": 0
                }
            },
            {
                "id": "purchasesOfInvestments",
                "metricKey": "purchasesOfInvestments",
                "metricDisplayName": "Purchases of Investments",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -40631000000,
                    "2020": -115148000000,
                    "2021": -109558000000,
                    "2022": -76923000000,
                    "2023": -29513000000
                }
            },
            {
                "id": "salesMaturitiesOfInvestments",
                "metricKey": "salesMaturitiesOfInvestments",
                "metricDisplayName": "Sales Maturities of Investments",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 98724000000,
                    "2020": 120483000000,
                    "2021": 106483000000,
                    "2022": 67363000000,
                    "2023": 45514000000
                }
            },
            {
                "id": "otherInvestingActivites",
                "metricKey": "otherInvestingActivites",
                "metricDisplayName": "Other Investing Activites",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -1078000000,
                    "2020": -791000000,
                    "2021": -352000000,
                    "2022": -1780000000,
                    "2023": -1337000000
                }
            },
            {
                "id": "netCashUsedForInvestingActivites",
                "metricKey": "netCashUsedForInvestingActivites",
                "metricDisplayName": "Net Cash Used for Investing Activites",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "totalBalance",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 45896000000,
                    "2020": -4289000000,
                    "2021": -14545000000,
                    "2022": -22354000000,
                    "2023": 3705000000
                }
            },
            {
                "id": "debtRepayment",
                "metricKey": "debtRepayment",
                "metricDisplayName": "Debt Repayment",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -8805000000,
                    "2020": -13592000000,
                    "2021": -8750000000,
                    "2022": -9543000000,
                    "2023": -11151000000
                }
            },
            {
                "id": "commonStockIssued",
                "metricKey": "commonStockIssued",
                "metricDisplayName": "Common Stock Issued",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 781000000,
                    "2020": 880000000,
                    "2021": 1105000000,
                    "2022": 0,
                    "2023": 1250000000
                }
            },
            {
                "id": "commonStockRepurchased",
                "metricKey": "commonStockRepurchased",
                "metricDisplayName": "Common Stock Repurchased",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -66897000000,
                    "2020": -72358000000,
                    "2021": -85971000000,
                    "2022": -89402000000,
                    "2023": -77550000000
                }
            },
            {
                "id": "dividendsPaid",
                "metricKey": "dividendsPaid",
                "metricDisplayName": "Dividends Paid",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -14119000000,
                    "2020": -14081000000,
                    "2021": -14467000000,
                    "2022": -14841000000,
                    "2023": -15025000000
                }
            },
            {
                "id": "otherFinancingActivites",
                "metricKey": "otherFinancingActivites",
                "metricDisplayName": "Other Financing Activites",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -1936000000,
                    "2020": 12331000000,
                    "2021": 14730000000,
                    "2022": 3037000000,
                    "2023": -6012000000
                }
            },
            {
                "id": "netCashUsedProvidedByFinancingActivities",
                "metricKey": "netCashUsedProvidedByFinancingActivities",
                "metricDisplayName": "Net Cash Used Provided by Financing Activities",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "totalBalance",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": -90976000000,
                    "2020": -86820000000,
                    "2021": -93353000000,
                    "2022": -110749000000,
                    "2023": -108488000000
                }
            },
            {
                "id": "netChangeInCash",
                "metricKey": "netChangeInCash",
                "metricDisplayName": "Net Change in Cash",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "bold",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 24311000000,
                    "2020": -10435000000,
                    "2021": -3860000000,
                    "2022": -10952000000,
                    "2023": 5760000000
                }
            },
            {
                "id": "freeCashFlow",
                "metricKey": "freeCashFlow",
                "metricDisplayName": "Free Cash Flow",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "bold",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 58896000000,
                    "2020": 73365000000,
                    "2021": 92953000000,
                    "2022": 111443000000,
                    "2023": 99584000000
                },
                "growth": {
                    "2019": 0,
                    "2020": 0.245,
                    "2021": 0.267,
                    "2022": 0.199,
                    "2023": -0.106
                }
            },
            {
                "id": "cashAtBeginningOfPeriod",
                "metricKey": "cashAtBeginningOfPeriod",
                "metricDisplayName": "Cash at Beginning of Period",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 25913000000,
                    "2020": 50224000000,
                    "2021": 39789000000,
                    "2022": 35929000000,
                    "2023": 24977000000
                }
            },
            {
                "id": "cashAtEndOfPeriod",
                "metricKey": "cashAtEndOfPeriod",
                "metricDisplayName": "Cash at End of Period",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 50224000000,
                    "2020": 39789000000,
                    "2021": 35929000000,
                    "2022": 24977000000,
                    "2023": 30737000000
                }
            },
            {
                "id": "operatingCashFlow",
                "metricKey": "operatingCashFlow",
                "metricDisplayName": "Operating Cash Flow",
                "cellFormat": "LargeDigitCell",
                "redNumber": false,
                "rowType": "regular",
                "metricSuffix": "",
                "isPercentage": false,
                "values": {
                    "2019": 69391000000,
                    "2020": 80674000000,
                    "2021": 104038000000,
                    "2022": 122151000000,
                    "2023": 110543000000
                }
            }
        ]
    })

    const [selectedStatement, setSelectedStatment] = useState({value: 1, label: 'Income Statement'})

    const selectStatement = (value) => {
        setSelectedStatment(value)
    }

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        '& .super-app-theme--regular': {
            backgroundColor: 'white',
        },
        '& .super-app-theme--total': {
            backgroundColor: 'white',
            borderTop: '1px solid #B0B0B0',
            borderBottom: '0.8px solid rgb(192,192,192)',
        },
        '& .super-app-theme--totalIncome': {
            backgroundColor: '#F8F8F8',
            borderTop: '1px solid #B0B0B0',
            borderBottom: '0.8px solid rgb(192,192,192)',
            fontWeight: '900',
        },
        '& .super-app-theme--totalBalance': {
            backgroundColor: '#F1F2F7',
            borderTop: '1px solid #B0B0B0',
            borderBottom: '0.8px solid rgb(192,192,192)',
            fontWeight: '900',
        },
        '& .super-app-theme--bold': {
            backgroundColor: '#F8F8F8',
            fontWeight: '900',
        },
        '& .super-app-theme--boldIncome': {
            fontWeight: '900',
        },
    }));

    useEffect(() => {
        console.log(stockData)

        const periodsBase = [
            { 
                field: 'metricDisplayName',
                headerName: '', 
                width:  400, 
                align: 'left',
                headerAlign: 'center',
                renderCell: (params) => {
                    return (
                        <div className='flex items-center'>  
                            <div className='font-semibold'>{params.row.metricDisplayName}</div>
                        </div>
                    )
                }
            }
        ]

        const calculateDisplayPeriods = () => {
            console.log('selectedPeriods', selectedPeriods)
            //Crear un array co los años entre los selecciondos
            const years = []
            for (let i = selectedPeriods.startDate.numericValue; i <= selectedPeriods.endDate.numericValue; i++) {
                years.push(i)
            }
            
            const periods = years.map(year => {
                return {
                    field: year,
                    headerName: year,
                    yearValue: year,
                    width: 140,
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (params) => {
                        return (
                            
                            params.row.cellFormat == 'LargeDigitCell' ? (
                                <LargeDigitCell params={params} year={year}/>
                            )
                            : params.row.cellFormat == 'SmallDigitCell' ? (
                                <SmallDigitCell params={params} year={year}/>
                            )
                            : params.row.cellFormat == 'RatioCell' ? (
                                <RatioCell params={params} year={year}/>
                            )
                            : null                            
                        )
                    }
                }
            })

            setScreenData({
                ...screenData,
                periods: [...periodsBase, ...periods]
            })
        }

        calculateDisplayPeriods()

    }, [stockData, selectedPeriods])


    return (
    <div className='flex flex-col'>
        <div className='flex flex-row items-center justify-center mt-8 space-x-12'>
            <div className={selectedStatement.value == 1 ? 'text-sm text-[#3f51b5] p-3 bg-slate-100 rounded-xl font-medium cursor-pointer' : 'font-medium text-sm text-gray-700 p-3 cursor-pointer'} onClick={() => selectStatement({value: 1, label:'Income Statement'})}>
            Income Statement
            </div>
            <div className={selectedStatement.value == 2 ? 'text-sm text-[#3f51b5] p-3 bg-slate-100 rounded-xl font-medium cursor-pointer' : 'font-medium text-sm text-gray-700 p-3 cursor-pointer'} onClick={() => selectStatement({value: 2, label:'Balance Sheet'})}>
            Balance Sheet
            </div>
            <div className={selectedStatement.value == 3 ? 'text-sm text-[#3f51b5] p-3 bg-slate-100 rounded-xl font-medium cursor-pointer' : 'font-medium text-sm text-gray-700 p-3 cursor-pointer'} onClick={() => selectStatement({value: 3, label:'Cash Flow Statement'})}>
            Cash Flow Statement
            </div>
        </div>
        {
            selectedStatement.value == 1 ? (
                <div className='p-8 w-full'>
                    <StyledDataGrid
                        rows={
                            statementsDatesFormated.income
                        }
                        columns={screenData.periods}
                        stickyHeader
                        rowHeight={45}
                        sx={{ 
                            maxHeight: 'calc(100vh - 100px)',
                            maxWidth: 'calc(100vw - 430px)',
                            fontFamily:'poppins', 
                            fontSize: '13px',
                            '&, [class^=MuiDataGrid]': { 
                                borderLeft: 'none', 
                                borderTop: 'none', 
                                borderRight:'none'
                            },
                            '& .MuiDataGrid-columnHeader': {
                                color: '#3f51b5', // Ajusta el color del texto del encabezado
                            }  
                        }}
                        paginationModel={{
                        page: 0,
                        pageSize: 50,
                        }}
                        pageSizeOptions={[5, 10, 30]}
                        scro
                        hideFooterPagination={true}
                        disableColumnMenu={true}
                        hideFooter={true}
                        sortModel={[]}
                        disableColumnFilter={true}
                        getRowClassName={(params) => `super-app-theme--${params.row.rowType}`}
                    />
                </div>
            )
            : selectedStatement.value == 2 ? (
                <div className='p-8'>
                    <StyledDataGrid
                        rows={
                            statementsDatesFormated.balance
                        }
                        columns={screenData.periods}
                        stickyHeader
                        rowHeight={40}
                        sx={{ 
                            maxHeight: 'calc(100vh - 100px)',
                            maxWidth: 'calc(100vw - 430px)',
                            fontFamily:'poppins', 
                            overflowX: 'scroll',
                            fontSize: '13px',
                            '&, [class^=MuiDataGrid]': { 
                                borderLeft: 'none', 
                                borderTop: 'none', 
                                borderRight:'none'
                            },
                            '& .MuiDataGrid-columnHeader': {
                                color: '#3f51b5', // Ajusta el color del texto del encabezado
                            }    
                        }}
                        paginationModel={{
                        page: 0,
                        pageSize: 50,
                        }}
                        pageSizeOptions={[5, 10, 30]}
                        hideFooterPagination={true}
                        disableColumnMenu={true}
                        hideFooter={true}
                        sortModel={[]}
                        disableColumnFilter={true}
                        getRowClassName={(params) => `super-app-theme--${params.row.rowType}`}
                    />
                </div>
            )
            : selectedStatement.value == 3 ? (
                <div className='p-8'>
                    <StyledDataGrid
                        rows={
                            statementsDatesFormated.cash
                        }
                        columns={screenData.periods}
                        stickyHeader
                        rowHeight={40}
                        sx={{ 
                            maxHeight: 'calc(100vh - 100px)',
                            maxWidth: 'calc(100vw - 430px)',
                            fontFamily:'poppins', 
                            overflowX: 'scroll',
                            fontSize: '13px',
                            '&, [class^=MuiDataGrid]': { 
                                borderLeft: 'none', 
                                borderTop: 'none', 
                                borderRight:'none'
                            },
                            '& .MuiDataGrid-columnHeader': {
                                color: '#3f51b5', // Ajusta el color del texto del encabezado
                            }  
                        }}
                        paginationModel={{
                        page: 0,
                        pageSize: 50,
                        }}
                        pageSizeOptions={[5, 10, 30]}
                        hideFooterPagination={true}
                        hideFooter={true}
                        disableColumnMenu={true}
                        sortModel={[]}
                        disableColumnFilter={true}
                        getRowClassName={(params) => `super-app-theme--${params.row.rowType}`}
                    />
                </div>
            )
            :
            null
        }
    </div>
    )
}

export default FinancialsTab