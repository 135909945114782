import React, {useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import '../styles/LateralMenu.css'
import logo from '../assets/logo.png'

const LateralMenu = () => {

  const location = useLocation();

  const [activeURL, setActiveURL] = useState(location.pathname);

  return (
    <div className='lateral-menu-container'>
      <div className='lateral-menu-logo-helper'>
        <img 
          src={logo}
          alt='logo' 
          className='lateral-menu-logo'
        />
      </div>
      
      <div className='lateral-menu-divider'>

      </div>

      <div className='lateral-menu-menu-container'>
        <div className='lateral-menu-menu-container-padding-helper'>

          <div className='lateral-menu-section-indicator'>
            Menu
          </div>
          <div className='lateral-menu-principal-items'>

            <Link to={'/Home/Dashboard'} style={{textDecoration:'none', width: '100%'}}>
              <div className={ activeURL === '/Home/Dashboard' ? 'lateral-menu-item-active' : 'lateral-menu-item'}>
                <div  className='lateral-menu-item-padding-helper'>
                  <span className={ activeURL === '/Home/Dashboard' ? 'lateral-menu-item-icon-active' : 'lateral-menu-item-icon'}>
                    <i class="fa-solid fa-gauge"></i>
                  </span>
                  <div className={ activeURL === '/Home/Dashboard' ? 'lateral-menu-item-text-active' : 'lateral-menu-item-text'}>
                    Dashboard
                  </div>
                </div>
              </div>
            </Link>

            <Link to={'/Home/Portfolio'} style={{textDecoration:'none', width: '100%'}}>
              <div className={ activeURL === '/Home/Portfolio' ? 'lateral-menu-item-active' : activeURL === '/Home/CreatePortfolio' ? 'lateral-menu-item-active' : 'lateral-menu-item'}>
                <div className='lateral-menu-item-padding-helper'>
                  <span className={ activeURL === '/Home/Portfolio'? 'lateral-menu-item-icon-active' : activeURL === '/Home/CreatePortfolio' ? 'lateral-menu-item-icon-active' : 'lateral-menu-item-icon'}>
                    <i class="fa-solid fa-wallet"></i>
                  </span>
                  <div className={ activeURL === '/Home/Portfolio' ? 'lateral-menu-item-text-active' : activeURL === '/Home/CreatePortfolio' ? 'lateral-menu-item-text-active' : 'lateral-menu-item-text'}>
                    Portfolios
                  </div>
                </div>
              </div>
            </Link>

            <Link to={'/Home/Watchlist'} style={{textDecoration:'none', width: '100%'}}>
              <div className={ activeURL === '/Home/Watchlist' ? 'lateral-menu-item-active' : activeURL === '/Home/CreateWatchlist' ? 'lateral-menu-item-active' : 'lateral-menu-item'}>
                <div className='lateral-menu-item-padding-helper'>
                  <span className={ activeURL === '/Home/Watchlist' ? 'lateral-menu-item-icon-active' : activeURL === '/Home/CreateWatchlist' ? 'lateral-menu-item-icon-active' : 'lateral-menu-item-icon'}>
                    <i class="fa-solid fa-glasses"></i>
                  </span>
                  <div className={ activeURL === '/Home/Watchlist' ? 'lateral-menu-item-text-active' : activeURL === '/Home/CreateWatchlist' ? 'lateral-menu-item-text-active' : 'lateral-menu-item-text'}>
                    Watchlists
                  </div>
                </div>
              </div>
            </Link>

            <Link to={'/Home/Analyzer/AAPL'} style={{textDecoration:'none', width: '100%'}}>
              <div className={ activeURL === '/Home/Analyzer' ? 'lateral-menu-item-active' : 'lateral-menu-item'}>
                <div className='lateral-menu-item-padding-helper'>
                  <span className={ activeURL === '/Home/Analyzer' ? 'lateral-menu-item-icon-active' : 'lateral-menu-item-icon'}>
                    <i class="fa-solid fa-binoculars"></i>
                  </span>
                  <div className={ activeURL === '/Home/Analyzer' ? 'lateral-menu-item-text-active' : 'lateral-menu-item-text'}>
                    Analyzer
                  </div>
                </div>
              </div>
            </Link>

            <Link to={'/Home/Thesis'} style={{textDecoration:'none', width: '100%'}}>
              <div className={ activeURL === '/Home/Thesis' ? 'lateral-menu-item-active' : 'lateral-menu-item'}>
                <div className='lateral-menu-item-padding-helper'>
                  <span className={ activeURL === '/Home/Thesis' ? 'lateral-menu-item-icon-active' : 'lateral-menu-item-icon'}>
                    <i class="fa-solid fa-swatchbook"></i>
                  </span>
                  <div className={ activeURL === '/Home/Thesis' ? 'lateral-menu-item-text-active' : 'lateral-menu-item-text'}>
                    Thesis
                  </div>
                </div>
              </div>
            </Link>

          </div>

          <div className='lateral-menu-section-indicator'>
            Others
          </div>

          <div className='lateral-menu-principal-items'>

            <Link to={'/Home/Settings'} style={{textDecoration:'none', width: '100%'}}>
              <div className={ activeURL === '/Home/Settings' ? 'lateral-menu-item-active' : 'lateral-menu-item'}>
                <div className='lateral-menu-item-padding-helper'>
                  <span className={ activeURL === '/Home/Settings' ? 'lateral-menu-item-icon-active' : 'lateral-menu-item-icon'}>
                    <i class="fa-solid fa-gear"></i>
                  </span>
                  <div className={ activeURL === '/Home/Settings' ? 'lateral-menu-item-text-active' : 'lateral-menu-item-text'}>
                    Settings
                  </div>
                </div>
              </div>
            </Link>

            <Link to={'/Home/Account'} style={{textDecoration:'none', width: '100%'}}>
              <div className={ activeURL === '/Home/Account' ? 'lateral-menu-item-active' : 'lateral-menu-item'}>
                <div className='lateral-menu-item-padding-helper'>
                  <span className={ activeURL === '/Home/Account' ? 'lateral-menu-item-icon-active' : 'lateral-menu-item-icon'}>
                    <i class="fa-solid fa-user"></i>
                  </span>
                  <div className={ activeURL === '/Home/Account' ? 'lateral-menu-item-text-active' : 'lateral-menu-item-text'}>
                    Account
                  </div>
                </div>
              </div>
            </Link>

            <Link to={'/Home/Help'} style={{textDecoration:'none', width: '100%'}}>
              <div className={ activeURL === '/Home/Help' ? 'lateral-menu-item-active' : 'lateral-menu-item'}>
                <div className='lateral-menu-item-padding-helper'>
                  <span className={ activeURL === '/Home/Help' ? 'lateral-menu-item-icon-active' : 'lateral-menu-item-icon'}>
                    <i class="fa-solid fa-circle-info"></i>
                  </span>
                  <div className={ activeURL === '/Home/Help' ? 'lateral-menu-item-text-active' : 'lateral-menu-item-text'}>
                    Help
                  </div>
                </div>
              </div>
            </Link>

          </div>
          
        </div>
      </div>
    </div>
  )
}

export default LateralMenu