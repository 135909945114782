import React, {useState, useEffect} from 'react'
import '../styles/Analyzer.css'
import PriceChart from '../customComponents/charts/PriceChart'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Switch } from "../components/ui/switch";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import { useParams } from 'react-router-dom'
import supabase from '../config/supabase';
import ValuationTab from '../customComponents/stockDetailTabs/ValuationTab';
import ScoringTab from '../customComponents/stockDetailTabs/ScoringTab';
import FinancialsTabs from '../customComponents/stockDetailTabs/FinancialsTab';
import EstimatesTab from '../customComponents/stockDetailTabs/EstimatesTab';
import CapitalStructureTab from '../customComponents/stockDetailTabs/InsiderTradingTab';
import NewsTab from '../customComponents/stockDetailTabs/NewsTab';
import frame from '../assets/frame.png'


const Analyzer = () => {

  const { stockId } = useParams()

  const [stockData, setStockData] = useState({})

  const [availablePeriodsYrs, setAvailablePeriodsYrs] = useState([
    {
      period: '2019',
      value: new Date(2019, 0, 1),
      numericValue: 2019
    },
    {
      period: '2020',
      value: new Date(2020, 0, 1),
      numericValue: 2020
    },
    {
      period: '2021',
      value: new Date(2021, 0, 1),
      numericValue: 2021
    },
    {
      period: '2022',
      value: new Date(2022, 0, 1),
      numericValue: 2022
    },
    {
      period: '2023',
      value: new Date(2023, 0, 1),
      numericValue: 2023
    }
  ])

  const [availablePeriodsQtrs, setAvailablePeriodsQtrs] = useState([
    {
      period: 'Q1 21',
      value: new Date(2021, 0, 1)
    },
    {
      period: 'Q2 21',
      value: new Date(2021, 3, 1)
    },
    {
      period: 'Q3 21',
      value: new Date(2021, 6, 1)
    },
    {
      period: 'Q4 21',
      value: new Date(2021, 9, 1)
    },
    {
      period: 'Q1 22',
      value: new Date(2022, 0, 1)
    },
    {
      period: 'Q2 22',
      value: new Date(2022, 3, 1)
    },
    {
      period: 'Q3 22',
      value: new Date(2022, 6, 1)
    },
    {
      period: 'Q4 22',
      value: new Date(2022, 9, 1)
    },
    {
      period: 'Q1 23',
      value: new Date(2023, 0, 1)
    },
    {
      period: 'Q2 23',
      value: new Date(2023, 3, 1)
    },
    {
      period: 'Q3 23',
      value: new Date(2023, 6, 1)
    },
    {
      period: 'Q4 23',
      value: new Date(2023, 9, 1)
    },
    {
      period: 'Q1 24',
      value: new Date(2024, 0, 1)
    },
    {
      period: 'Q2 24',
      value: new Date(2024, 3, 1)
    },
    {
      period: 'Q3 24',
      value: new Date(2024, 6, 1)
    },
    {
      period: 'Q4 24',
      value: new Date(2024, 9, 1)
    }
  ])

  const [stockPriceData, setStockPriceData] = useState({
    dates: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    values: [140, 160, 156, 170, 174, 186, 179]
  })

  const [overviewStats, setOverviewStats] = useState([
    {
      kpi: 'Market Cap',
      value: '1.84T',
    },
    {
      kpi: 'P/E Ratio',
      value: '34.21',
    },
    {
      kpi: 'EPS',
      value: '3.28',
    },
    {
      kpi: 'Dividend',
      value: '0.82',
    },
    {
      kpi: 'Yield',
      value: '0.61%',
    },
    {
      kpi: 'Beta',
      value: '1.23',
    },
    {
      kpi: 'ROE',
      value: '0.23',
    },
    {
      kpi: 'ROA',
      value: '0.15',
    },
    {
      kpi: 'Debt/Equity',
      value: '1.23',
    },
    {
      kpi: 'Current Ratio',
      value: '1.23',
    },
    {
      kpi: 'Market Cap',
      value: '1.84T',
    },
    {
      kpi: 'P/E Ratio',
      value: '34.21',
    },
    {
      kpi: 'EPS',
      value: '3.28',
    },
    {
      kpi: 'Dividend',
      value: '0.82',
    },
    {
      kpi: 'Yield',
      value: '0.61%',
    },
    {
      kpi: 'Beta',
      value: '1.23',
    },
    {
      kpi: 'ROE',
      value: '0.23',
    },
    {
      kpi: 'ROA',
      value: '0.15',
    },
    {
      kpi: 'Debt/Equity',
      value: '1.23',
    },
    {
      kpi: 'Current Ratio',
      value: '1.23',
    }
  ])

  const [timeFrameSelector, setTimeFrameSelector] = useState(false)
  const handleTimeFrameChange = (checked) => {
    setTimeFrameSelector(checked);
    if(checked === false) {
      setStartDate(availablePeriodsYrs[0])
      setEndDate(availablePeriodsYrs[availablePeriodsYrs.length - 1])
    } else {
      setStartDate(availablePeriodsQtrs[0])
      setEndDate(availablePeriodsQtrs[availablePeriodsQtrs.length - 1])
    }

  };

  const [tabValue, setTabValue] = useState('one')
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [startDate, setStartDate] = useState(availablePeriodsYrs && timeFrameSelector === false ? availablePeriodsYrs[0] : null)
  const handleStartDateChange = (event) => {
    if(event.target.value.value <= endDate.value) {
    setStartDate(event.target.value)
    } else {
      setStartDate(event.target.value)
      setEndDate(event.target.value)
    }
  }

  const [endDate, setEndDate] = useState(availablePeriodsYrs && timeFrameSelector === false ? availablePeriodsYrs[availablePeriodsYrs.length - 1] : null)
  const handleEndDateChange = (event) => {
    if(event.target.value.value >= startDate.value) {
    setEndDate(event.target.value)
    } else {
      alert('End date must be greater than start date')
      return
    }
  }

  //Popover
  const [volumeInput, setVolumeInput] = useState('')
  const [priceInput, setPriceInput] = useState('')

  const addStock = () => {
    console.log('Adding stock')
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
      setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  //Popover end

  useEffect(() => {
    const {data, error} = supabase.functions.invoke('get-stock-data-by-id', { body: {stockId: stockId} })
    .then(response => {
      setStockData(response.data.data[0])
    })

  }, [stockId])

  return (
    <div className='an-container'>
      <div className='an-padding-helper'>
      <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{
              borderRadius: '25px',
              marginTop: '10px'
            }}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
        >
          <div className='topbar-popover-container'>
            <div className='topbar-popover-container-padding-helper'>
                <div style={{paddingTop: '12px', paddingBottom: '12px', paddingLeft:'8px', paddingRight: '8px', display:'flex', alignItems:'center', flexDirection:'column'}}>
                    <div className='cp-form-field-container'>
                        <TextField   
                            label='Stock' 
                            variant='outlined'
                            size='small'
                            disabled={true}
                            InputLabelProps={{ style: { color: '#AEB1B4', fontFamily:'poppins', fontSize: 13  }}}
                            inputProps={{style: {fontSize: 13}}}
                            value={'AAPL'}
                            sx={{
                            width: '250px', 
                            height: '40px',
                            backgroundColor: 'none', 
                            borderRadius: '10px',
                            "& fieldset": { border: 'none' }
                            }}
                        />
                        <span className='topbar-searchbar-icon' style={{color:'#CECECE', paddingRight:'30px'}}>
                            <i class="fa-solid fa-arrow-trend-up fa-sm"></i>
                        </span>
                    </div>
                    
                      <div className='cp-form-field-container'>
                          <TextField   
                              label='N of shares' 
                              variant='outlined'
                              size='small'
                              InputLabelProps={{ style: { color: '#AEB1B4', fontFamily:'poppins', fontSize: 13  }}}
                              inputProps={{style: {fontSize: 13}}}
                              onChange={(event) => {
                                  setVolumeInput(event.target.value.replace(/,/g, '.'))
                              }}
                              value={volumeInput}
                              sx={{
                              width: '250px', 
                              height: '40px',
                              backgroundColor: 'none', 
                              borderRadius: '10px',
                              "& fieldset": { border: 'none' }
                              }}
                          />
                          <span className='topbar-searchbar-icon' style={{color:'#CECECE', paddingRight:'30px'}}>
                              <i class="fa-solid fa-circle-half-stroke fa-sm"></i>
                          </span>
                      </div>
                        
                   
                      <div className='cp-form-field-container'>
                          <TextField   
                              label='Average price' 
                              variant='outlined'
                              size='small'
                              InputLabelProps={{ style: { color: '#AEB1B4', fontFamily:'poppins', fontSize: 13 }}}
                              inputProps={{style: {fontSize: 13}}}
                              onChange={(event) => {
                                  setPriceInput(event.target.value.replace(/,/g, '.'))
                              }}
                              value={priceInput}
                              sx={{
                              width: '250px', 
                              height: '40px',
                              backgroundColor: 'none', 
                              borderRadius: '10px',
                              "& fieldset": { border: 'none'}
                              }}
                          />
                          <span className='topbar-searchbar-icon' style={{color:'#CECECE', paddingRight:'30px'}}>
                              <i class="fa-solid fa-dollar-sign fa-sm"></i>
                          </span>
                      </div>
                       
                    
                    <div className='flex flex-row bg-[#5a6acf] text-white py-3 px-4 mt-1 rounded-xl cursor-pointer items-center text-sm' onClick={()=> addStock()}>
                        <span style={{fontSize:'13px', marginRight:'10px'}}>
                            <i className="fas fa-plus"></i>
                        </span>
                        <div>
                            Add Stock
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </Popover>
        <div className='portfolio-topbar-container'>
          <div className='portfolio-general-title'>
            Analyzer
          </div>
          <div className='portfolio-vertical-spacer'></div>
          <div className='an-stock-name-logo-container'>
            <div>
              <img src='https://firebasestorage.googleapis.com/v0/b/ground-value.appspot.com/o/Static%20Assets%2Fapple%20logo.png?alt=media&token=54fe07f1-c1f5-4d09-8083-54abe00201c4' style={{maxWidth:'45px', display:'flex', alignItems:'center'}}/>
            </div>
            <div className='an-name-ticker-container'>
              <div className='an-stock-title'>
                Apple
              </div>
              <div className='an-stock-ticker'>
                AAPL
              </div>
            </div>
          </div>
          <div className='portfolio-add-stock-button' aria-describedby={id} onClick={handleClick}>
            <span style={{fontSize:'11px', marginRight:'10px'}}>
              <i className="fas fa-plus"></i>
            </span>
            <div>
              Add Stock
            </div>
          </div>
          <div className='portfolio-vertical-spacer'></div>
          <div className='an-stock-price'>
            435.43$
          </div>
          <div className='portfolio-vertical-spacer'></div>
          <div className='flex flex-row items-center'>
            <img src={frame} style={{maxWidth:'20px', marginRight:'10px'}}/>
            <div className='an-value-index-title'>
              Value Index
            </div>
            <div className='an-value-index-value'>
              91
            </div>
          </div>
        </div>

        <div className='flex flex-row mt-5 min-w-full'>
          <div className='flex w-3/5'>
            <PriceChart stockPriceData={stockPriceData}/>
          </div>
          <div className='flex w-2/5 max-w-[550px] p-6'>
            <div className='w-full p-6 shadow-3xl rounded-2xl bg-white'>
              <div className='text-md font-medium'>
                Company Overview
              </div>
              <div className='h-full w-full'>
                <div className='flex flex-row h-full items-center'>
                  <div className='flex flex-col w-1/2 mr-4'>
                    {
                      overviewStats.map((stat, index) => (
                        index < 10 ?
                        <div key={index} className='flex flex-row justify-between mb-2'>
                          <div className='text-xs flex flex-row items-center'>
                            <div className='w-[3px] h-3 bg-[#3f51b5] rounded-sm mr-2.5'>
                            </div>
                            <div>
                              {stat.kpi}
                            </div>
                          </div>
                          <div className='text-xs font-medium'>
                            {stat.value}
                          </div>
                        </div>
                        :
                        null
                      ))
                    }
                  </div>
                  <div className='flex flex-col w-1/2 ml-4'>
                    {
                      overviewStats.map((stat, index) => (
                        index >= 10 ?
                        <div key={index} className='flex flex-row justify-between mb-2'>
                          <div className='text-xs flex flex-row items-center'>
                            <div className='w-[3px] h-3 bg-[#3f51b5] rounded-sm mr-2.5'>
                            </div>
                            <div>
                              {stat.kpi}
                            </div>
                          </div>
                          <div className='text-xs font-medium'>
                            {stat.value}
                          </div>
                        </div>
                        :
                        null
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='mt-7 flex w-full flex-col'>
          <div className='flex w-full flex-row justify-between border-[1px] border-b-gray border-t-0 border-r-0 border-l-0 items-center'>
            <div className='flex w-full'>
              <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                sx={{
                  '& .MuiTabs-indicator': {
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#3f51b5',
                  }
                }}
              >
                <Tab 
                  value="one" 
                  label={<div className='flex flex-row'> <img src={frame} style={{maxWidth:'18px', marginRight:'19px', marginRight: '10px'}}/> Scoring</div>}
                  sx={{ 
                    color: 'gray',
                    textTransform: 'none',
                    fontFamily: 'Poppins',
                    marginRight: '15px',
                    '&.Mui-selected': {
                      color: '#3f51b5',
                    },
                    '&.Mui-focusVisible': {
                      backgroundColor: '#3f51b5',
                    },
                  }}
                />
                <Tab 
                  value="two" 
                  label="Valuation" 
                  sx={{ 
                    color: 'gray',
                    textTransform: 'none',
                    fontFamily: 'Poppins',
                    marginRight: '15px',
                    '&.Mui-selected': {
                      color: '#3f51b5',
                    },
                    '&.Mui-focusVisible': {
                      backgroundColor: '#3f51b5',
                    },
                  }}
                />
                <Tab 
                  value="three" 
                  label="Financials" 
                  sx={{ 
                    color: 'gray',
                    textTransform: 'none',
                    fontFamily: 'Poppins',
                    marginRight: '15px',
                    '&.Mui-selected': {
                      color: '#3f51b5',
                    },
                    '&.Mui-focusVisible': {
                      backgroundColor: '#3f51b5',
                    },
                  }}
                />
                <Tab 
                  value="four" 
                  label="Estimates" 
                  sx={{ 
                    color: 'gray',
                    textTransform: 'none',
                    fontFamily: 'Poppins',
                    marginRight: '15px',
                    '&.Mui-selected': {
                      color: '#3f51b5',
                    },
                    '&.Mui-focusVisible': {
                      backgroundColor: '#3f51b5',
                    },
                  }}
                />
                <Tab 
                  value="five" 
                  label="Insider Trading" 
                  sx={{ 
                    color: 'gray',
                    textTransform: 'none',
                    fontFamily: 'Poppins',
                    marginRight: '15px',
                    '&.Mui-selected': {
                      color: '#3f51b5',
                    },
                    '&.Mui-focusVisible': {
                      backgroundColor: '#3f51b5',
                    },
                  }}
                />
                 <Tab 
                  value="six" 
                  label="News" 
                  sx={{ 
                    color: 'gray',
                    textTransform: 'none',
                    fontFamily: 'Poppins',
                    marginRight: '15px',
                    '&.Mui-selected': {
                      color: '#3f51b5',
                    },
                    '&.Mui-focusVisible': {
                      backgroundColor: '#3f51b5',
                    },
                  }}
                />
              </Tabs>
            </div>
            <div className='flex flex-row'>
              <div className='flex flex-row items-center mr-7'>
                <div className='text-sm mr-2'>
                  Yr
                </div>
                <Switch
                  className='mr-1'
                  checked={timeFrameSelector}
                  onCheckedChange={handleTimeFrameChange}
                />
                <div className='text-sm ml-1'>
                  Q
                </div>
              </div>
              <div className='flex flex-row items-center'>
                <div className='text-sm font-semibold flex flex-row items-center mr-3'>
                  From
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={startDate}
                    sx={{
                      minWidth: '90px',
                      fontFamily:'poppins', 
                      fontSize:'13px',
                      borderColor:'white', 
                      boxShadow: "none",
                      padding: 'none',
                      ".MuiOutlinedInput-notchedOutline": { border: 0},
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                    }}
                    label="From"
                    onChange={handleStartDateChange}
                  >
                    {
                      
                      timeFrameSelector === false && availablePeriodsYrs.length > 0 ?
                      availablePeriodsYrs.map((item, index) => {
                        return (
                          <MenuItem 
                            sx={{fontFamily: 'poppins', height: '50px', fontSize:'13px'}} 
                            key={index} 
                            value={item}
                          >
                            <div style={{display:'flex', flexDirection:'row'}}>
                              <div>
                                {item.period}
                              </div> 
                            </div>
                          </MenuItem>
                        )
                      })
                      : timeFrameSelector === true && availablePeriodsQtrs.length > 0 ?
                      availablePeriodsQtrs.map((item, index) => {
                        return (
                          <MenuItem 
                            sx={{fontFamily: 'poppins', height: '50px', fontSize:'13px'}} 
                            key={index} 
                            value={item}
                          >
                            <div style={{display:'flex', flexDirection:'row'}}>
                              <div>
                                {item.period}
                              </div> 
                            </div>
                          </MenuItem>
                        )
                      })
                      :
                      null
                    }
                  </Select>
                </div>
                <div className='text-sm font-semibold flex flex-row items-center'>
                  To
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={endDate}
                    sx={{
                      minWidth: '90px',
                      fontFamily:'poppins', 
                      fontSize:'13px',
                      borderColor:'white', 
                      boxShadow: "none",
                      padding: 'none',
                      ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          border: 0,
                        },
                    }}
                    label="From"
                    onChange={handleEndDateChange}
                  >
                    {
                      
                      timeFrameSelector === false && availablePeriodsYrs.length > 0 ?
                      availablePeriodsYrs.map((item, index) => {
                        return (
                          <MenuItem 
                            sx={{fontFamily: 'poppins', height: '50px', fontSize:'13px'}} 
                            key={index} 
                            value={item}
                          >
                            <div style={{display:'flex', flexDirection:'row'}}>
                              <div>
                                {item.period}
                              </div> 
                            </div>
                          </MenuItem>
                        )
                      })
                      : timeFrameSelector === true && availablePeriodsQtrs.length > 0 ?
                      availablePeriodsQtrs.map((item, index) => {
                        return (
                          <MenuItem 
                            sx={{fontFamily: 'poppins', height: '50px', fontSize:'13px'}} 
                            key={index} 
                            value={item}
                          >
                            <div style={{display:'flex', flexDirection:'row'}}>
                              <div>
                                {item.period}
                              </div> 
                            </div>
                          </MenuItem>
                        )
                      })
                      :
                      null
                    }
                  </Select>
                </div>
              </div>
              
            </div>
          </div>
          
          <div>
            
          </div>
        </div>

        <div>
          <div>
            {
              tabValue === 'one' ?
              <ScoringTab />
              : tabValue === 'two' ?
              <ValuationTab stockData={stockData? stockData : null} selectedPeriods={{startDate: startDate, endDate: endDate}}/>
              : tabValue === 'three' ?
              <FinancialsTabs stockData={stockData? stockData : null} selectedPeriods={{startDate: startDate, endDate: endDate}}/>
              : tabValue === 'four' ?
              <EstimatesTab />
              : tabValue === 'five' ?
              <CapitalStructureTab />
              : tabValue === 'six' ?
              <NewsTab />
              : null
            }
          </div>
        </div>

      </div>
    </div>
  )
}

export default Analyzer