import React, { useState } from 'react'
import OpportunityCard from '../customComponents/OpportunityCard'
import '../styles/Dashboard.css'

const Dashboard = () => {

  const [opportunities, setOpportunities] = useState([
    {
      id: '1', 
      company: 'Spotify', 
      ticker: 'SPTY', 
      logo:'https://firebasestorage.googleapis.com/v0/b/ground-value.appspot.com/o/Static%20Assets%2Fspoty%20logo.png?alt=media&token=5be9e71b-7cba-4348-bda1-b224848c686f', 
      basedThesis: 'Top Value 1.0', 
      valueIndex: 87, 
      mainKPIS: [
        {id:'1', kpi:'PER', value: 23, polarity: 'asc', polaritySentiment: 'good', unit: null, indexCont: 'high'},
        {id:'1', kpi:'DIV', value: 3.4, polarity: 'asc', polaritySentiment: 'good', unit: '%', indexCont: 'high'},
        {id:'1', kpi:'RevToTG 5y', value: 23, polarity: null, polaritySentiment: null, unit: null, indexCont: 'high'},
        {id:'1', kpi:'LTM Price', value: 125, polarity: null, polaritySentiment: null, unit: '$', indexCont: 'high'},
      ] 
    },
    {
      id: '2', 
      company: 'Apple INC', 
      ticker: 'AAPL', 
      logo:'https://firebasestorage.googleapis.com/v0/b/ground-value.appspot.com/o/Static%20Assets%2Fapple%20logo.png?alt=media&token=54fe07f1-c1f5-4d09-8083-54abe00201c4', 
      basedThesis: 'Top Value 1.0', 
      valueIndex: 91, 
      mainKPIS: [
        {id:'1', kpi:'PER', value: 23, polarity: 'asc', polaritySentiment: 'good', unit: null, indexCont: 'high'},
        {id:'1', kpi:'RevTo 10y', value: 3.4, polarity: 'asc', polaritySentiment: 'good', unit: '%', indexCont: 'high'},
        {id:'1', kpi:'RevToTG 5y', value: 23, polarity: null, polaritySentiment: null, unit: null, indexCont: 'high'},
        {id:'1', kpi:'LTM Price', value: 125, polarity: null, polaritySentiment: null, unit: '$', indexCont: 'high'},
      ] 
    },
    {
      id: '2', 
      company: 'Apple INC', 
      ticker: 'AAPL', 
      logo:'https://firebasestorage.googleapis.com/v0/b/ground-value.appspot.com/o/Static%20Assets%2Fapple%20logo.png?alt=media&token=54fe07f1-c1f5-4d09-8083-54abe00201c4', 
      basedThesis: 'Top Value 1.0', 
      valueIndex: 91, 
      mainKPIS: [
        {id:'1', kpi:'PER', value: 23, polarity: 'asc', polaritySentiment: 'good', unit: null, indexCont: 'high'},
        {id:'1', kpi:'RevTo 10y', value: 3.4, polarity: 'asc', polaritySentiment: 'good', unit: '%', indexCont: 'high'},
        {id:'1', kpi:'RevToTG 5y', value: 23, polarity: null, polaritySentiment: null, unit: null, indexCont: 'high'},
        {id:'1', kpi:'LTM Price', value: 125, polarity: null, polaritySentiment: null, unit: '$', indexCont: 'high'},
      ] 
    },
    {
      id: '1', 
      company: 'Spotify', 
      ticker: 'SPTY', 
      logo:'https://firebasestorage.googleapis.com/v0/b/ground-value.appspot.com/o/Static%20Assets%2Fspoty%20logo.png?alt=media&token=5be9e71b-7cba-4348-bda1-b224848c686f', 
      basedThesis: 'Top Value 1.0', 
      valueIndex: 87, 
      mainKPIS: [
        {id:'1', kpi:'PER', value: 23, polarity: 'asc', polaritySentiment: 'good', unit: null, indexCont: 'high'},
        {id:'1', kpi:'DIV', value: 3.4, polarity: 'asc', polaritySentiment: 'good', unit: '%', indexCont: 'high'},
        {id:'1', kpi:'RevToTG 5y', value: 23, polarity: null, polaritySentiment: null, unit: null, indexCont: 'high'},
        {id:'1', kpi:'LTM Price', value: 125, polarity: null, polaritySentiment: null, unit: '$', indexCont: 'high'},
      ] 
    },
    {
      id: '1', 
      company: 'Spotify', 
      ticker: 'SPTY', 
      logo:'https://firebasestorage.googleapis.com/v0/b/ground-value.appspot.com/o/Static%20Assets%2Fspoty%20logo.png?alt=media&token=5be9e71b-7cba-4348-bda1-b224848c686f', 
      basedThesis: 'Top Value 1.0', 
      valueIndex: 87, 
      mainKPIS: [
        {id:'1', kpi:'PER', value: 23, polarity: 'asc', polaritySentiment: 'good', unit: null, indexCont: 'high'},
        {id:'1', kpi:'DIV', value: 3.4, polarity: 'asc', polaritySentiment: 'good', unit: '%', indexCont: 'high'},
        {id:'1', kpi:'RevToTG 5y', value: 23, polarity: null, polaritySentiment: null, unit: null, indexCont: 'high'},
        {id:'1', kpi:'LTM Price', value: 125, polarity: null, polaritySentiment: null, unit: '$', indexCont: 'high'},
      ] 
    },
    {
      id: '1', 
      company: 'Spotify', 
      ticker: 'SPTY', 
      logo:'https://firebasestorage.googleapis.com/v0/b/ground-value.appspot.com/o/Static%20Assets%2Fspoty%20logo.png?alt=media&token=5be9e71b-7cba-4348-bda1-b224848c686f', 
      basedThesis: 'Top Value 1.0', 
      valueIndex: 87, 
      mainKPIS: [
        {id:'1', kpi:'PER', value: 23, polarity: 'asc', polaritySentiment: 'good', unit: null, indexCont: 'high'},
        {id:'1', kpi:'DIV', value: 3.4, polarity: 'asc', polaritySentiment: 'good', unit: '%', indexCont: 'high'},
        {id:'1', kpi:'RevToTG 5y', value: 23, polarity: null, polaritySentiment: null, unit: null, indexCont: 'high'},
        {id:'1', kpi:'LTM Price', value: 125, polarity: null, polaritySentiment: null, unit: '$', indexCont: 'high'},
      ] 
    },
    {
      id: '2', 
      company: 'Apple INC', 
      ticker: 'AAPL', 
      logo:'https://firebasestorage.googleapis.com/v0/b/ground-value.appspot.com/o/Static%20Assets%2Fapple%20logo.png?alt=media&token=54fe07f1-c1f5-4d09-8083-54abe00201c4', 
      basedThesis: 'Top Value 1.0', 
      valueIndex: 91, 
      mainKPIS: [
        {id:'1', kpi:'PER', value: 23, polarity: 'asc', polaritySentiment: 'good', unit: null, indexCont: 'high'},
        {id:'1', kpi:'RevTo 10y', value: 3.4, polarity: 'asc', polaritySentiment: 'good', unit: '%', indexCont: 'high'},
        {id:'1', kpi:'RevToTG 5y', value: 23, polarity: null, polaritySentiment: null, unit: null, indexCont: 'high'},
        {id:'1', kpi:'LTM Price', value: 125, polarity: null, polaritySentiment: null, unit: '$', indexCont: 'high'},
      ] 
    },
    {
      id: '1', 
      company: 'Spotify', 
      ticker: 'SPTY', 
      logo:'https://firebasestorage.googleapis.com/v0/b/ground-value.appspot.com/o/Static%20Assets%2Fspoty%20logo.png?alt=media&token=5be9e71b-7cba-4348-bda1-b224848c686f', 
      basedThesis: 'Top Value 1.0', 
      valueIndex: 87, 
      mainKPIS: [
        {id:'1', kpi:'PER', value: 23, polarity: 'asc', polaritySentiment: 'good', unit: null, indexCont: 'high'},
        {id:'1', kpi:'DIV', value: 3.4, polarity: 'asc', polaritySentiment: 'good', unit: '%', indexCont: 'high'},
        {id:'1', kpi:'RevToTG 5y', value: 23, polarity: null, polaritySentiment: null, unit: null, indexCont: 'high'},
        {id:'1', kpi:'LTM Price', value: 125, polarity: null, polaritySentiment: null, unit: '$', indexCont: 'high'},
      ] 
    }
  ])

  return (
    <div className='dash-container'>
      <div className='dash-padding-helper'>
        <div className='portfolio-general-title'>
          Dashboard
        </div>

        <div className='dash-kpis-bar-container'>
          <div className='dash-kpi-container'>
            <div className='dash-kpi-title'>
              Portfolio Value
            </div>
            <div className='dash-kpi-value'>
              USD 1.874.143
            </div>
            <div style={{display:'flex', flexDirection:'row', marginTop:'5px', alignItems:'center'}}>
              <div className='dash-growth-line-data-positive'>
                <span style={{marginRight: '7px', fontSize:'11px'}}>
                  <i class="fa-solid fa-arrow-up"></i>
                </span>
                3.1%
              </div>
              <div className='dash-soft-text'>
                vs last month
              </div>
            </div>
          </div>  

          <div className='dash-kpi-spacer'></div>

          <div className='dash-kpi-container'>
            <div className='dash-kpi-title'>
              Portfolio Growth
            </div>
            <div className='dash-kpi-portfolio-growth-value'>
              <span style={{marginRight: '7px', fontSize:'16px'}}>
                <i class="fa-solid fa-arrow-up"></i>
              </span>
              12%
            </div>
            <div style={{display:'flex', flexDirection:'row', marginTop:'5px', alignItems:'center'}}>
              <div className='dash-soft-text'>
                Year to date
              </div>
            </div>
          </div>  

          <div className='dash-kpi-spacer'></div>

          <div className='dash-kpi-container'>
            <div className='dash-kpi-title'>
              Total Opportunities
            </div>
            <div className='dash-kpi-value'>
              32
            </div>
            <div style={{display:'flex', flexDirection:'row', marginTop:'5px', alignItems:'center'}}>
              <div className='dash-growth-line-data-positive'>
                <span style={{marginRight: '7px', fontSize:'11px'}}>
                  <i class="fa-solid fa-arrow-up"></i>
                </span>
                3
              </div>
              <div className='dash-soft-text'>
                vs last week
              </div>
            </div>
          </div> 

          <div className='dash-kpi-spacer'></div>

          <div className='dash-kpi-container'>
            <div className='dash-kpi-title'>
              Thesis
            </div>
            <div className='dash-kpi-value'>
              4
            </div>
            <div style={{display:'flex', flexDirection:'row', marginTop:'5px', alignItems:'center'}}>
              <div className='dash-growth-line-data-positive'>
                <span style={{marginRight: '7px', fontSize:'11px'}}>
                  <i class="fa-solid fa-arrow-up"></i>
                </span>
                1
              </div>
              <div className='dash-soft-text'>
                vs last month
              </div>
            </div>
          </div> 

        </div>

        <div className='dash-opportunities-cards-container'>
          {
            opportunities.map((opportunity, index) => {
              return (
                <OpportunityCard key={index} opportunity={opportunity}/>
              )
            })
          }
        </div>  

      </div>
    </div>
  )
}

export default Dashboard