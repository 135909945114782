import React, {useState, useRef, useEffect} from 'react'
import { SupabaseUserAuth } from '../context/SupabaseAuthContext';
import supabase from '../config/supabase'
import { DataGrid } from '@mui/x-data-grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Skeleton } from '@mui/material';
import Select from '@mui/material/Select';
import * as echarts from 'echarts';
import Cell from '../customComponents/Cell';
import StockMainCell from '../customComponents/StockMainCell';
import ValueIndexCell from '../customComponents/ValueIndexCell';
import OptionsCell from '../customComponents/OptionsCell';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Switch } from "../components/ui/switch";
import {
  Select as SelectComponent,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import '../styles/Portfolio.css'


const Portfolio = () => {

  const { session } = SupabaseUserAuth();

  const navigate = useNavigate();

  const [benchmarks, setBenchmarks] = useState([
    { value: 25, label: 'Teleco', icon: 'value' },
    { value: 32, label: 'Oil & Gas', icon: 'value' },
    { value: 12, label: 'Tech', icon: 'dividend' },
    { value: 14, label: 'Comodities', icon: 'dividend' },
  ]);

  const [portfolios, setPortfolios] = useState(null)
  const [selectedPortfolio, setSelectedPortfolio] = useState(null)

  const [screenData, setScreenData] = useState({
    overview:{
      totalValue: 1543234,
      valueCurrency: 'USD',
      growth: 3.1,
      growthPolarity: 'asc',
      opportunitiesValueIndex: 85,
      capitalAllocation:[
        { value: 25, name: 'Teleco' },
        { value: 32, name: 'Oil & Gas' },
        { value: 12, name: 'Tech' },
        { value: 14, name: 'Comodities' },
        { value: 17, name: 'Health' }
      ]
    },
    stocks:[
      { id: 'fdsd', stock: 'Apple', tikr: 'AAPL', portfolioWeight: 15, price: {value: 154, polarity: null, polaritySentiment: null, indexCont: null, unit: '$'}, PERvs10y: {value:3.2, polarity: 'asc', polaritySentiment:'good', indexCont: 'med', unit: ''}, per: {value:35, polarity: null, polaritySentiment: null, indexCont: 'med', unit: ''}, valueIndex: 90 },
      { id: 'fsdafhdsu', stock: 'Apple', tikr: 'AAPL', portfolioWeight: 15, price: {value: 54, polarity: null, polaritySentiment: null, indexCont: null, unit: '$'}, PERvs10y: {value:3.2, polarity: 'desc', polaritySentiment:'bad', indexCont: 'med', unit: ''}, per: {value:35, polarity: null, polaritySentiment: null, indexCont: 'med', unit: ''}, valueIndex: 43 },
      { id: 'sdjaki', stock: 'Apple', tikr: 'AAPL', portfolioWeight: 15, price: {value: 54, polarity: null, polaritySentiment: null, indexCont: null, unit: '$'}, PERvs10y: {value:3.2, polarity: 'asc', polaritySentiment:'good', indexCont: 'med', unit: ''}, per: {value:23, polarity: null, polaritySentiment: null, indexCont: 'med', unit: ''}, valueIndex: 73 },
      { id: 'iuihfuh', stock: 'Apple', tikr: 'AAPL', portfolioWeight: 15, price: {value: 65, polarity: null, polaritySentiment: null, indexCont: null, unit: '$'}, PERvs10y: {value:3.2, polarity: 'asc', polaritySentiment:'good', indexCont: 'med', unit: ''}, per: {value:12, polarity: null, polaritySentiment: null, indexCont: 'med', unit: ''}, valueIndex: 50 },
      { id: 'dsjafurhbu', stock: 'Apple', tikr: 'AAPL', portfolioWeight: 15, price: {value: 76, polarity: null, polaritySentiment: null, indexCont: null, unit: '$'}, PERvs10y: {value:3.2, polarity: 'asc', polaritySentiment:'good', indexCont: 'med', unit: ''}, per: {value:11, polarity: null, polaritySentiment: null, indexCont: 'med', unit: ''}, valueIndex: 75 },
      { id: 'sdauhcyr33j', stock: 'Apple', tikr: 'AAPL', portfolioWeight: 15, price: {value: 58, polarity: null, polaritySentiment: null, indexCont: null, unit: '$'}, PERvs10y: {value:3.2, polarity: 'asc', polaritySentiment:'good', indexCont: 'med', unit: ''}, per: {value:21, polarity: null, polaritySentiment: null, indexCont: 'med', unit: ''}, valueIndex: 60 },
      { id: 'isdjfughbsau', stock: 'Apple', tikr: 'AAPL', portfolioWeight: 15, price: {value: 345, polarity: null, polaritySentiment: null, indexCont: null, unit: '$'}, PERvs10y: {value:3.2, polarity: 'asc', polaritySentiment:'good', indexCont: 'med', unit: ''}, per: {value:23, polarity: null, polaritySentiment: null, indexCont: 'med', unit: ''}, valueIndex: 87 },
      { id: 'iedyuiq', stock: 'Apple', tikr: 'AAPL', portfolioWeight: 15, price: {value: 648, polarity: null, polaritySentiment: null, indexCont: null, unit: '$'}, PERvs10y: {value:3.2, polarity: 'asc', polaritySentiment:'good', indexCont: 'med', unit: ''}, per: {value:10, polarity: null, polaritySentiment: null, indexCont: 'med', unit: ''}, valueIndex: 85 },
      { id: 'ewjfudh', stock: 'Apple', tikr: 'AAPL', portfolioWeight: 15, price: {value: 76, polarity: null, polaritySentiment: null, indexCont: null, unit: '$'}, PERvs10y: {value:3.2, polarity: 'asc', polaritySentiment:'good', indexCont: 'med', unit: ''}, per: {value:11, polarity: null, polaritySentiment: null, indexCont: 'med', unit: ''}, valueIndex: 31 },
      { id: 'dsjbfyuhdgb', stock: 'Apple', tikr: 'AAPL', portfolioWeight: 15, price: {value: 58, polarity: null, polaritySentiment: null, indexCont: null, unit: '$'}, PERvs10y: {value:3.2, polarity: 'asc', polaritySentiment:'good', indexCont: 'med', unit: ''}, per: {value:21, polarity: null, polaritySentiment: null, indexCont: 'med', unit: ''}, valueIndex: 21 },
      { id: 'rigbsfeg', stock: 'Apple', tikr: 'AAPL', portfolioWeight: 15, price: {value: 345, polarity: null, polaritySentiment: null, indexCont: null, unit: '$'}, PERvs10y: {value:3.2, polarity: 'asc', polaritySentiment:'good', indexCont: 'med', unit: ''}, per: {value:23, polarity: null, polaritySentiment: null, indexCont: 'med', unit: ''}, valueIndex: 72 },
      { id: 'dfsughfyu', stock: 'Apple', tikr: 'AAPL', portfolioWeight: 15, price: {value: 648, polarity: null, polaritySentiment: null, indexCont: null, unit: '$'}, PERvs10y: {value:3.2, polarity: 'asc', polaritySentiment:'good', indexCont: 'med', unit: ''}, per: {value:10, polarity: null, polaritySentiment: null, indexCont: 'med', unit: ''}, valueIndex: 70 }
    ],
    kpis:[
      { 
        field: 'stock', 
        headerName: '', 
        width: 150, 
        align: 'center', 
        headerAlign: 'center',
        renderCell: (params) => {
          return(
            <StockMainCell params={params}/> 
          )
        } 
      },
      { 
        field: 'PERvs10y', 
        headerName: 'PER vs 10y', 
        width: 140, 
        align: 'center', 
        headerAlign: 'center',
        renderCell: (params) => {
          return (
            <Cell params={params}/>
          );
        } 
      },
      { 
        field: 'price', 
        headerName: 'Price', 
        width: 140, 
        align: 'center', 
        headerAlign: 'center',
        renderCell: (params) => {
          return (
            <Cell params={params}/>
          );
        } 
      },
      {
        field: 'per',
        headerName: 'PER',
        type: 'number',
        width: 140,
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => {
          return (
            <Cell params={params}/>
          );
        },
      },
      {
        field: 'valueIndex',
        headerName: 'Value Index',
        type: 'number',
        width: 140,
        align: 'center',
        sticky: 'right',
        headerAlign: 'center',
        renderCell: (params) => {
          return (
            <ValueIndexCell params={params}/>
          );
        },
      }
    ]
  })

  const stockOpportunities = screenData.stocks.filter(item => item.valueIndex >= screenData.overview.opportunitiesValueIndex)

  const [totalValueFormated, setTotalValueFormated] = useState(screenData.overview.totalValue.toLocaleString('es-ES'))

  const [opportunitiesChecked, setOpportunitiesChecked] = useState(false);

  const [benchmarkValue, setBenchmarkValue] = React.useState('');
  const [inputValue, setInputValue] = React.useState('');

  const defaultColumns = [
    { 
        field: 'stock', 
        headerName: 'Stock', 
        width: 140, 
        align: 'left', 
        headerAlign: 'center',
        renderCell: (params) => {
        return(
          <div style={{display:'flex', flexDirection:'column'}}>
            <div style={{fontSize:'15px', marginBottom:'3px'}}>
                {params.row.name}
            </div>
            <div style={{fontSize:'11px', fontWeight:'300'}}>
                {params.row.tikr}
            </div>
          </div> 
        )
        } 
    }
  ]

  const handleSwitchChange = (checked) => {
    setOpportunitiesChecked(checked);
  };

  const handlePortfolioChange = (event) => {
    setSelectedPortfolio(event.target.value);
  };

  const handleBenchmarkChange = (event) => {
    setBenchmarkValue(event.target.value);
  };

  const chartRef = useRef(null);

  useEffect(() => {

    const getPortfolios = async () => {
      const { data, error } = await supabase.functions.invoke('get-portfolios-by-user-id', { 
        body: {
          userId: session.user.id
        }
       });

      if (data && data.data.length > 0 ) {
        setPortfolios(data.data)
        setSelectedPortfolio(data.data[0]);
      }

    }

    getPortfolios()

  }, [session]);

  useEffect(() => {
    const chart = echarts.init(chartRef.current);

    const options = {
      tooltip: {
        trigger: 'item'
      },
      series: [
        {
          name: 'Percentage',
          type: 'pie',
          radius: ['43%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 4,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: false,
              fontSize: 40,
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: screenData.overview.capitalAllocation
        }
      ]
    };

    chart.setOption(options);

    return () => {
      chart.dispose();
    };
  }, [screenData.overview.capitalAllocation]);

  return (
    <div className='portfolio-container'>
      <div className='portfolio-padding-helper'>

        <div className='portfolio-topbar-container'>
          <div className='portfolio-general-title'>
            Portfolio
          </div>
          <div className='portfolio-vertical-spacer'></div>
          <div className='portfolio-topbar-container-portfolio-name'>
            {
              portfolios != null && portfolios.length > 0 ?
              (
                <>
                  <SelectComponent
                    value={selectedPortfolio}
                    className="z-30"
                    onValueChange={(value) => {
                      setSelectedPortfolio(value)
                    }}
                  >
                    <SelectTrigger className="max-w-[250px]">
                      <SelectValue placeholder="Select a portfolio" />
                    </SelectTrigger>
                    <SelectContent>
                      
                      {
                        portfolios != null ?
                        portfolios.map((item, index) => {
                          return (
                            <SelectItem 
                              key={index} 
                              value={item}
                            >
                              <div style={{display:'flex', flexDirection:'row'}}>
                                <div style={{display:'flex', alignItems: 'center', fontSize:'13px', color:'grey', minWidth:'50px'}}>
                                  {item.rating.toFixed(1)}
                                  <span style={{fontSize: '10px'}}>
                                    <i className="fas fa-star" style={{color:'#FFC107', marginLeft:'5px', marginRight: '7px'}}></i>
                                  </span>
                                </div>
                                <div>
                                  {item.name}
                                </div> 
                              </div>
                            </SelectItem>
                          )
                        })
                        :
                        null
                      }
                    </SelectContent>
                  </SelectComponent>
                </>
              ):
              (
                <Skeleton variant="text" width={160} height={40} />
              )
            }
          </div>
          <div className='portfolio-add-stock-button' onClick={() => navigate(`/Home/EditPortfolio/${selectedPortfolio.id}`)}>
            <span style={{fontSize:'12.5px'}}>
              <i class="fa-solid fa-pencil"></i>
            </span>
          </div>
          <Link to={'/Home/CreatePortfolio'}>
            <div className='portfolio-add-stock-button'>
              <span style={{fontSize:'11px', marginRight:'10px'}}>
                <i className="fas fa-plus"></i>
              </span>
              <div>
                New Portfolio
              </div>
            </div>
          </Link>
          <div className='portfolio-vertical-spacer'></div>
          <div className='portfolio-opportunities-toggle-container'>
            <div className='portfolio-opportunities-toggle-title'>
              Only Opportunities
            </div>
            <Switch
              className='ml-1'
              checked={opportunitiesChecked}
              onCheckedChange={handleSwitchChange}
            />
          </div>
        </div>

        <div className='portfolio-stats-container'>

          <div className='portfolio-stats-container-left'>
            <div className='portfolio-stats-container-overview'>
              <div className='portfolio-stats-container-overview-title'>
                Portfolio Overview
              </div>
              <div className='portfolio-stats-container-overview-dollars'>
                {screenData.overview.valueCurrency + ' ' + totalValueFormated}
              </div>
              <div className='portfolio-overview-data-container'>
                {
                  screenData.overview.growthPolarity === 'asc' ?
                  (
                    <span style={{fontSize:'13px', marginRight:'7px', color: '#149D52'}}>
                      <i class="fa-solid fa-arrow-up"></i>
                    </span>
                  )
                  :
                  screenData.overview.growthPolarity === 'desc' ?
                  (
                    <span style={{fontSize:'13px', marginRight:'7px', color: '#EC5252'}}>
                      <i class="fa-solid fa-arrow-down"></i>
                    </span>
                  )
                  :
                  null
                }
                <div className='portfolio-stats-container-overview-growth'>
                  {
                    screenData.overview.growthPolarity === 'asc' ?
                    (<div style={{color:'#149D52'}}>{screenData.overview.growth}</div>)
                    :
                    screenData.overview.growthPolarity === 'desc' ?
                    (<div style={{color:'#EC5252'}}>{screenData.overview.growth}</div>)
                    :
                    null
                  }
                </div>
                <div className='portfolio-stats-container-overview-last-month-text'>
                  vs last month
                </div>
              </div>
            </div>
            <div ref={chartRef} style={{ width: '140px', height: '100%', marginLeft:'15px' }}></div>
            <div className='portfolio-big-spacer'></div>
            <div className='portfolio-stats-container-allocation'>
              <div className='portfolio-stats-container-overview-title'>
                Capital Allocation
              </div>
              <div className='portfolio-allocation-carrousel-container'>
                {
                  screenData.overview.capitalAllocation.map((item, index) => {
                    return (
                      <div key={index} className='portfolio-allocation-carrousel-item'>
                        <div>
                          <span>
                            <i className="fas fa-circle" style={{color:'#5A67BA', marginRight:'9px', fontSize:'9px', paddingTop:'11.5px'}}></i>
                          </span>
                        </div>
                        <div>
                          <div className='portfolio-allocation-carrousel-item-title'>
                            {item.name}
                          </div>
                          <div className='portfolio-allocation-carrousel-item-value'>
                            {item.value}%
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>

          <div className='portfolio-stats-container-right'>
            <div className='portfolio-benchmark-selection-container'>
              <div className='portfolio-benchmar-input-container'>
                {
                  benchmarks != null && benchmarks.length > 0 ?
                  (
                    <>
                      <SelectComponent
                        value={benchmarkValue}
                        className="z-30"
                        onValueChange={(value) => {
                          setBenchmarkValue(value)
                        }}
                      >
                        <SelectTrigger className="max-w-[250px]">
                          <SelectValue placeholder="Select a Thesis" />
                        </SelectTrigger>
                        <SelectContent>
                          
                          {
                            benchmarks != null ?
                            benchmarks.map((item, index) => {
                              return (
                                <SelectItem 
                                  key={index} 
                                  value={item}
                                >
                                  <div className='flex flex-row items-center'>
                                    <div className='mr-3 text-gray-500'>
                                      <span>
                                        <i class="fa-solid fa-swatchbook fa-sm"></i>
                                      </span>
                                    </div>
                                    <div style={{marginRight: '20px'}}>
                                      {item.label}
                                    </div> 
                                  </div>
                                </SelectItem>
                              )
                            })
                            :
                            null
                          }
                        </SelectContent>
                      </SelectComponent>
                    </>
                  ):
                  (
                    <Skeleton variant="text" width={160} height={55} />
                  )
                }
              </div>
            </div>
          </div>

        </div>

        <div className='portfolio-table-container'>
          <div className='portfolio-table-spacer'></div>

          <div className='portfolio-table-table'>
            <DataGrid
              rows={
                opportunitiesChecked ?
                stockOpportunities
                : selectedPortfolio != null ? selectedPortfolio.portfolio_stocks
                : []
              }
              columns={
                benchmarkValue != '' ? screenData.kpis
                : defaultColumns
              }
              stickyHeader
              rowHeight={65}
              sx={{ 
                maxHeight: 'calc(100vh - 340px)',
                maxWidth:'calc(100vw - 340px)',
                fontFamily:'poppins', 
                overflowX: 'scroll',
                '&, [class^=MuiDataGrid]': { 
                  borderLeft: 'none', 
                  borderTop: 'none', 
                  borderRight:'none' 
                }  
              }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 30]}
            />
          </div>

        </div>
        
      </div>
    </div>
  )
}

export default Portfolio