import React, { useState } from 'react'
import '../styles/Login.css'
import { TextField } from '@mui/material'
import { Button } from '@mui/material'
import { Link, Navigate } from 'react-router-dom'
import { SupabaseUserAuth } from '../context/SupabaseAuthContext'
import { Alert } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import supabase from '../config/supabase'
import logo from '../assets/logo.png'
import { Input } from "../components/ui/input"

const Register = () => {

    const { session, supabaseMailSignUp } = SupabaseUserAuth()

    //Alert form validation
    const [open, setOpen] = useState(false);
    const [openError, setOpenError] = useState(false);
    //Alert form validation

    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [mail, setMail] = useState('')
    const [password, setPassword] = useState('')

    const [userCreated, setUserCreated] = useState(false)
   
    const updateName = (event) =>{
        setName(event.target.value)
    }

    const updateSurname = (event) =>{
        setSurname(event.target.value)
    }

    const updateMail = (event) =>{
        setMail(event.target.value)
    }

    const updatePassword = (event) =>{
        setPassword(event.target.value)
    }

    const validateMailFormat = (mail) => {
        // Expresión regular para validar un correo electrónico
        const patron = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return patron.test(mail);
    }

    const createUser = async () =>{
        if(mail !== '' && password !== '' && password.length >= 6 && name !== '' && surname !== '' && validateMailFormat(mail)){
            try {

                //Sign up supabase
                const fetch = await supabaseMailSignUp(mail, password)
                console.log(fetch)

                if(fetch.session === null){
                    console.log("Error al crear el usuario")
                    setOpenError(true)
                    return
                   
                }else{
                    console.log("Usuario creado")
                }
                //End sign up supabase

                //Dar de alta el usuario en la base de datos
                if(fetch.session !== null){

                    const user = {
                        name: name,
                        surname: surname,
                        mail: mail,
                        id: fetch.user.id,
                        rol: 'user',
                        subscription: 'free'
                    }

                    const { data, error } = await supabase
                    .from('users')
                    .insert([user])
                    .select()

                    if(error){
                        console.log(error)
                        setOpenError(true)
                    }else{
                        setUserCreated(true)
                    }
                       
                }else{
                    setOpenError(true)
                }     
            } catch (error) {
                console.log(error) 
            }
        
        }else{
            console.log("Revisa los campos del formulario")
            setOpen(true)
        }
    }
    
    if(session != null && userCreated){
        return <Navigate to='/Home/Dashboard' />
    }

  return (
    <div className='log-container'>
        <div className='log-left-zone'>
            <div>

            </div>
        </div>
        <div className='log-right-zone'>
            <div className='log-logo-container'>
                <div className='log-title'>
                    <img src={logo} alt='logo' className='h-9 mb-8'/>
                </div>        
            </div>
            <div className='registro-alerta-campos'>
                <Collapse in={open}>
                    <Alert
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="medium"
                        onClick={() => {
                            setOpen(false);
                        }}
                        >
                            <span>
                                <i class="fa-solid fa-xmark"></i>
                            </span>
                        </IconButton>
                    }
                    sx={{ mb: 2, fontSize:'16px', borderRadius:'10px', alignItems:'center'}}
                    severity="warning"
                    >
                        All fields are required
                    </Alert>
                </Collapse>
                <Collapse in={openError}>
                    <Alert
                    action={
                        <IconButton
                        aria-label="close"
                        color="inherit"
                        size="medium"
                        onClick={() => {
                            setOpenError(false);
                        }}
                        >
                            <span>
                                <i class="fa-solid fa-xmark"></i>
                            </span>
                        </IconButton>
                    }
                    sx={{ mb: 2, fontSize:'16px', borderRadius:'10px', alignItems:'center'}}
                    severity="warning"
                    >
                        An error ocurred during sign up
                    </Alert>
                </Collapse>
            </div>
            <div className='log-form'>
                <div className='log-textfield'>
                    <Input
                        placeholder="Name"
                        value={name}
                        onChange={(event)=> updateName(event)}
                        className='w-72 h-10'
                    />
                </div>
                <div className='log-textfield'>
                    <Input
                        placeholder="Last name"
                        value={surname}
                        onChange={(event)=> updateSurname(event)}
                        className='w-72 h-10' 
                    />
                </div>
                <div className='log-textfield'>
                    <Input
                        type="email" 
                        placeholder="Email"
                        value={mail}
                        onChange={(event)=> updateMail(event)}
                        className='w-72 h-10' 
                    />
                </div>
                <div className='log-textfield'>
                    <Input
                        type="password" 
                        placeholder="Password"
                        value={password}
                        onChange={(event)=> updatePassword(event)}
                        className='w-72 h-10' 
                    />
                </div>
                <div>
                    <Button 
                        className='log-button' 
                        sx={{
                            backgroundColor:'#5A67BA', 
                            textTransform:'none', 
                            borderRadius: '20px', 
                            minWidth: '120px', 
                            height: '40px', 
                            marginTop: '15px', 
                            fontSize: '15px', 
                            paddingLeft:'35px', 
                            paddingRight:'35px',
                            fontFamily:'poppins'
                        }} 
                        variant='contained'
                        onClick={()=>{createUser()}}
                    >
                        Create an account
                        
                    </Button>
                </div>         
            </div>
            <div className='log-create-account-container'>
                <div className='log-create-account-text'>Already have an account?</div>
                <Link to={'/'}>
                    <div className='log-create-account-link'>Sign in</div>
                </Link>
                
            </div>
        </div>
    </div>
  )
}

export default Register