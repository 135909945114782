import React, {useState, useEffect} from 'react'
import { SupabaseUserAuth } from '../context/SupabaseAuthContext';
import { Skeleton } from '@mui/material';
import Rating from '@mui/material/Rating';
import { Switch } from "../components/ui/switch";
import TextField from '@mui/material/TextField';
import ThesisParamRow from '../customComponents/ThesisParamRow';
import { Input } from "../components/ui/input"
import '../styles/Portfolio.css'


const CreateThesis = () => {

  const { session } = SupabaseUserAuth();

  const [rating, setRating] = useState(0);
  const [active, setActive] = useState(true);
  const [name, setName] = useState('');
  
  const [parametersSelected, setParametersSelected] = useState([]);

  const addParam = () => {
    console.log('add param');
    setParametersSelected([...parametersSelected, {
      id: Math.random().toString(36),
      name: '',
      type: '',
      group: null,
      weight: 1,
      paramSelectionDetails: null
    }]);
  }

  useEffect(() => {


  }, [session, parametersSelected]);

  return (
    <div className='h-[calc(100%-75px)] w-full'>
      <div className='portfolio-padding-helper' style={{overflow: 'scroll', height:'100%'}}>

        <div className='portfolio-topbar-container' style={{display:'flex', justifyContent:'space-between'}}>
          <div className='flex flex-row items-center'>
            <div className='portfolio-general-title'>
              New Thesis
            </div>
            <div className='cp-rating-container'>
              <Rating
                name="simple-controlled"
                value={rating}
                onChange={(event, newValue) => {
                setRating(newValue);
                }}
              />
            </div>

            <div className='portfolio-vertical-spacer' style={{marginRight: '5px'}}></div>

            <div style={{display:'flex', alignItems:'center', height:'100%', marginLeft: '10px', marginBottom:'0px'}}>
              <Input
                placeholder="Name"
                value={name}
                onChange={(event)=> setName(event.target.value)}
                className='w-52 h-10'
              />
            </div>

            <div className='portfolio-vertical-spacer' style={{marginRight: '5px'}}></div>

            <div className='flex flex-row items-center ml-4'>
              <div className='text-sm'>
                Active
              </div>
              <Switch
                className='ml-2'
                checked={active}
                onCheckedChange={(checked) => setActive(checked)}
              />
            </div>
            
          </div>

          <div className='portfolio-add-stock-button'>
            <span style={{fontSize:'11px', marginRight:'10px'}}>
              <i className="fas fa-plus"></i>
            </span>
            <div>
              Create
            </div>
          </div>
          
        </div>

        <div className='portfolio-table-spacer'></div>

        <div>

          <div className='flex flex-row'>
            <div className=''>
              Total Params
            </div>
            <div className='flex items-center justify-center rounded-md h-6 w-6 ml-2 text-sm text-[#5A6ACF] bg-[#F1F3FF]'>
              {parametersSelected.length}
            </div>
          </div>

          <div className='mt-5 mb-12'>
            {
              parametersSelected.length === 0 ? (
                <div className='text-xs text-gray-400 mt-9'>
                  Add your first param
                </div>
              ):
              (
                parametersSelected.map((param, index) => (
                  <ThesisParamRow param={param} index={index} parametersSelected={parametersSelected} setParametersSelected={setParametersSelected} key={index} /> 
                ))
              )
              
            }
          </div>

          <div className='flex flex-row items-center bg-[#f5f5f5] w-fit rounded-full px-4 py-1.5 mb-16 cursor-pointer hover:bg-[#ebebeb]' onClick={()=>addParam()}>
            <span className='text-gray-500 text-xs'>
              <i className="fas fa-plus fa-xs mr-1"></i>
            </span>
            <span className='text-xs text-gray-500'>
              Add Parameter
            </span>
          </div>

        </div>
        
      </div>
    </div>
  )
}

export default CreateThesis