import React from 'react'

const StockMainCell = ({params}) => {
  return (
    <div style={{display:'flex', flexDirection:'column'}}>
      <div style={{fontSize:'15px', marginBottom:'3px'}}>
          {params.value}
      </div>
      <div style={{fontSize:'11px', fontWeight:'300'}}>
          {params.row.tikr + ' ' + '|' + ' ' + params.row.portfolioWeight + '%'} 
      </div>
    </div>
  )
}

export default StockMainCell