import React, {useState, useEffect} from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { SupabaseUserAuth } from '../context/SupabaseAuthContext'
import LateralMenu from '../customComponents/LateralMenu'
import '../styles/Home.css'
import TopBar from '../customComponents/TopBar'


const Home = () => {

  const { session } = SupabaseUserAuth()

  useEffect(() => {
    
  }, [session])

  //supabase
  if(session === null){
    return <Navigate to='/'/>
  }

  return (
    <div className='home-container'>
        <LateralMenu/>
        <div className='home-oultet-topbar-container'>
          <div>
            <TopBar/>
          </div>
          <Outlet style={{width:'100%', maxWidth:'100%'}}/>
        </div>
        
    </div>
  )
}

export default Home