import React, {useState ,useEffect} from 'react'
import { DataGrid } from '@mui/x-data-grid';

const ValuationTab = ({stockData, selectedPeriods}) => {

    const [screenData, setScreenData] = useState({
        metrics:[
        {
            id: 1,
            metricKey: 'TTMEnterpriseValueDIVRevenues',
            metricDisplayName: 'EV / Revenue',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 3,
            metricKey: 'TTMTotalEnterpriseValueDIVGrossProfit',
            metricDisplayName: 'EV / Gross Profit',
            metricSufix: 'x',
            values: { 2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 4,
            metricKey: 'TTMTotalEnterpriseValueDIVEBITDA',
            metricDisplayName: 'EV / EBITDA',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 5,
            metricKey: 'TTMTotalEnterpriseValueDIVEBIT',
            metricDisplayName: 'EV / EBIT',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 6,
            metricKey: 'TTMTotalEnterpriseValueDIVUnleveredFreeCashFlow',
            metricDisplayName: 'EV / Unlevered Free Cash Flow',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 2,
            metricKey: 'TTMPriceDIVSales',
            metricDisplayName: 'Price / Sales (PS)',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 7,
            metricKey: 'TTMPriceDIVDilutedEPS',
            metricDisplayName: 'Price / Diluted EPS (PER)',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 8,
            metricKey: 'TTMPriceDIVBookValuePerShare',
            metricDisplayName: 'Price / Book Value Per Share',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 9,
            metricKey: 'TTMPriceDIVTangibleBookValuePerShare',
            metricDisplayName: 'Price / Tangible Book Value Per Share',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 11,
            metricKey: 'TTMPriceDIVNetCurrentAssetValue',
            metricDisplayName: 'Price / Net Current Asset Value',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 10,
            metricKey: 'TTMMarketCapDIVLeveredFreeCashFlow',
            metricDisplayName: 'Market Cap / Levered Free Cash Flow',
            metricSufix: 'x',
            values: {2019: 3.4435,
            2020: 3.45345435,
            2021: 3.45345435,
            2022: 3.45345435}
        },
        {
            id: 12,
            metricKey: 'TTMDividendYield',
            metricDisplayName: 'Dividend Yield',
            metricSufix: '%',
            values: {2019: 0.5678,
            2020: 0.5678,
            2021: 0.5678,
            2022: 0.5678,}
        }
        ],
        periods:[]
    })

    useEffect(() => {
        console.log(stockData)

        const periodsBase = [
            { 
              field: 'metricDisplayName',
              headerName: '', 
              width:  400, 
              align: 'left', 
              headerAlign: 'center',
              renderCell: (params) => {
                  return (
                      <div className='flex items-center'>
                      <div className='flex flex-row items-end'>
                          <div className='font-semibold'>{params.row.metricDisplayName}</div>
                          <div className='ml-2 text-[11px] text-gray-400'>TTM</div>
                      </div>
                      </div>
                  )
              }
            }
        ]

        const calculateDisplayPeriods = () => {
            console.log('selectedPeriods', selectedPeriods)
            //Crear un array co los años entre los selecciondos
            const years = []
            for (let i = selectedPeriods.startDate.numericValue; i <= selectedPeriods.endDate.numericValue; i++) {
                years.push(i)
            }
            
            const periods = years.map(year => {
                return {
                    field: year,
                    headerName: year,
                    yearValue: year,
                    width: 140,
                    align: 'center',
                    headerAlign: 'center',
                    renderCell: (params) => {
                        return (
                            <div className='flex flex-row'>
                                <div >{Number(params.row.values[year]).toFixed(2)}</div>
                                <div>{params.row.metricSufix}</div> 
                            </div>
                              
                        )
                    }
                }
            })

            setScreenData({
                ...screenData,
                periods: [...periodsBase, ...periods]
            })
        }

        calculateDisplayPeriods()

    }, [stockData, selectedPeriods])

  return (
    <div className='flex flex-col'>
        <div className='p-8 w-full flex'>
            <DataGrid
              rows={
                screenData.metrics
              }
              columns={screenData.periods}
              stickyHeader
              rowHeight={40}
              sx={{ 
                maxHeight: 'calc(100vh - 230px)',
                maxWidth: 'calc(100vw - 430px)',
                fontFamily:'poppins', 
                overflowX: 'scroll',
                fontSize: '13px',
                '&, [class^=MuiDataGrid]': { 
                  borderLeft: 'none', 
                  borderTop: 'none', 
                  borderRight:'none'
                }  
              }}
              paginationModel={{
                page: 0,
                pageSize: 50,
              }}
              pageSizeOptions={[5, 10, 30]}
              hideFooterPagination={true}
              hideFooter={true}
              disableColumnMenu={true}
              disableColumnFilter={true}
            />
        </div>
    </div>
  )
}

export default ValuationTab