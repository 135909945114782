import React from 'react'

const StockAveragePriceCell = ({params}) => {

  const paramValue = params.value
  const rowData = params.row

  return (
    <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
      {
        paramValue === null ? '-' :
        paramValue + ' ' + ' $'
      }
    </div>
  )
}

export default StockAveragePriceCell