import React, {useState, useEffect} from 'react'
import supabase from '../config/supabase'
import { TextField } from '@mui/material'
import Popover from '@mui/material/Popover';
import CircularProgress from '@mui/material/CircularProgress';
import { SupabaseUserAuth } from '../context/SupabaseAuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import '../styles/TopBar.css'

const TopBar = () => {

  const { session, supabaseSignOut } = SupabaseUserAuth()
  const navigate = useNavigate()

  const[userData, setUserData] = useState(null)
  const [search, setSearch] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [searchResultsLoading, setSearchResultsLoading] = useState(false)
  const [searchResultsVisible, setSearchResultsVisible] = useState(true)

  //Popover

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  //Fin popover

  const updateSearch = (event) =>{
    setSearch(event.target.value)
  }

  const searchBlur = () => {
    setTimeout(() => {
      setSearchResultsVisible(false);
    }, 200);
  }

  const searchFocus = () => {
    if (searchResults.length > 0) {
      setSearchResultsVisible(true)
    } 
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setSearchResultsLoading(true)
      getSearchResults()
    }
  }

  const getSearchResults = async () => {
    const { data, error } = await supabase.functions.invoke('search-stocks-by-name', {
      body: { keyword: search }
    })
    setSearchResults(data)
    setSearchResultsVisible(true)
    setSearchResultsLoading(false)
  }

  const detailPageRedirection = (stockTicker) => {
    navigate(`/Home/Analyzer/${stockTicker}`)
  }
  
  useEffect(() => {
  
    const getUserData = async () => {

      const idUser = session.user.id

      const { data, error } = await supabase
      .from('users')
      .select('*')
      .eq('id', idUser)
    
      setUserData(data[0])
    }
    
    getUserData()

  }, [session])


  return (
    <div className='topbar-container'>
      <div className='topbar-padding-helper'>

        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{
              borderRadius: '25px'
            }}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
        >
          <div className='topbar-popover-container'>
            <div className='topbar-popover-container-padding-helper'>
              <div className='topbar-popover-item' onClick={()=>{supabaseSignOut()}}>
                <div className='topbar-name-text'>
                  Log Out
                </div>
                <span style={{color:'grey', paddingLeft:'15px', fontSize:'12px' }}>
                  <i class="fa-solid fa-arrow-right-from-bracket"></i>
                </span>
              </div>
            </div>
          </div>
        </Popover>

        <div className='flex flex-col items-center'>
          <div className='topbar-serachbar-container'>
            <TextField   
              label='Stock Search' 
              variant='outlined'
              InputLabelProps={{ style: { color: '#AEB1B4', fontFamily:'poppins', fontSize: '13px' } }}
              inputProps={{style: {fontFamily:'poppins'}}}
              onChange={(event)=> updateSearch(event)}
              onKeyDown={handleKeyDown}
              value={search}
              onBlur={searchBlur}
              onFocus={searchFocus}
              sx={{
                width: '420px', 
                backgroundColor: 'none', 
                borderRadius: '10px',
                "& fieldset": { border: 'none' }
              }}
            />
            <span className='topbar-searchbar-icon' style={{color:'#CECECE', paddingRight:'30px', fontSize:'13px'}}>
              <i className="fas fa-search"></i>
            </span>
          </div>
          <div className='w-[460px] bg-white rounded-md mt-3 flex flex-col '>
              {
                searchResultsLoading ? <div className='w-full flex flex-row items-center justify-center gap-3 py-2 text-sm'>Searching...</div> : 
                searchResultsVisible && searchResults.length > 0 ? 
                searchResults.map((stock, index) => {
                  return (
                    <div key={index} className='flex flex-row px-2 py-3 hover:bg-gray-100 transition-colors duration-200 cursor-pointer' onClick={() => {navigate(`/Home/Analyzer/${stock.symbol}`)}}>
                      <div className='text-blue-600 font-semibold text-xs mr-3 min-w-16'>
                        {stock.symbol}
                      </div>
                      <div className='text-gray-800 text-xs'>
                        {stock.name}
                      </div>
                    </div>
                  )
                }): null
              }
          </div>
        </div>


        <div className='topbar-action-buttons-container'>

          <div className='topbar-name-container' aria-describedby={id} onClick={handleClick}>
            <div className='topbar-name-text'>
              {
                userData === null ? '' : userData.name + ' ' + userData.surname
              }
            </div>
            <span className='topbar-action-buttons-icon' style={{paddingLeft: '10px', paddingTop: '3px', color:'#A2ABB3', fontSize:'11px'}}>
              <i class="fa-solid fa-angle-down"></i>              
            </span>
          </div>

          <div>
            <span className='topbar-action-buttons-icon' style={{paddingLeft: '30px', color:'#A2ABB3'}}>
              <i class="fas fa-bell"></i>
            </span>
          </div>
          
        </div>

      </div>
    </div>
  )
}

export default TopBar