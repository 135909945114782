import React from 'react'
import Popover from '@mui/material/Popover';

const DeleteStockCell = ({params, setStocks, stocks}) => {

  const { id: stockId } = params.row

  //Popover

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  //Fin popover

  const deleteStock = () => {
    console.log('delete stock')
    const newStocks = stocks.filter(stock => stock.id !== stockId)
    setStocks(newStocks)
  }

  return (
    <div>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            sx={{
              borderRadius: '25px'
            }}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
            }}
        >
          <div className='topbar-popover-container'>
            <div className='topbar-popover-container-padding-helper' onClick={() => deleteStock()}>
                <div className='topbar-popover-item'>
                    <span style={{color:'#9F0606', paddingRight:'15px', fontSize:'12px' }} >
                        <i class="fa-solid fa-trash-can"></i>
                    </span>
                    <div className='topbar-name-text' style={{color:'#9F0606'}}>
                        Delete Stock
                    </div>
                </div>
            </div>
          </div>
        </Popover>

        <span aria-describedby={id} onClick={handleClick} style={{cursor:'pointer'}}>
            <i class="fa-solid fa-ellipsis"></i>
        </span>
    </div>
  )
}

export default DeleteStockCell